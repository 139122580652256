import { useEffect, useState } from "react";
import {
  defaultModalTimeout,
  defaultSearchTimeout,
  saveTokenToLocalStorage,
} from "../../redux/functions/function";
import { ReactComponent as FacebookLogo } from "../templates/svg/facebook-logo.svg";
import { ReactComponent as GmailLogo } from "../templates/svg/gmail-logo.svg";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

// import components here...
import ButtonComponent from "../templates/ButtonComponent/ButtonComponent";
import H2Header from "../templates/HeaderComponent/H2Header";
import PageContentComponent from "../templates/PageContentComponent/PageContentComponent";
import ToasterComponent from "../templates/ToasterComponent/ToasterComponent";
import TransitionComponent from "../templates/TransitionComponent/TransitionComponent";
import { TokenModel } from "../../model/interface/TokenModel";

// import lottie
import Lottie from "lottie-react";
import CircularLoading from "../../lottie/circular_loading_theme_1.json";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import {
  getSessionData,
  getUtilities,
  setSocketConnection,
  setSocketResponseData,
  setUtilityCommodityTypeMethods,
  setUtilityCommodityTypes,
} from "../../redux/functions/storageSlice";
import {
  getCommodityTypeMethods,
  getCommodityTypes,
  postLog_inAPI,
} from "../../redux/functions/API";

import { useUser } from "../../context/authContext";
import { useWebSocket } from "../../services/ws/WebSocketService";

const LoginComponent = () => {
  // declare navigation here...
  const navigate = useNavigate();
  // destructuring useUser() context for passing user data
  const { setUserRoleContext } = useUser();
  // declare our states here...
  const [hasInputError, setHasInputError] = useState(true);
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const [message, setToastMessage] = useState("");
  const [toastIcon, setToastIcon] = useState("success");
  const [showToast, setShowToast] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [isShowTransitionPage, setIsShowTransitionPage] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState<boolean>(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const sessionData = useAppSelector(getSessionData);
  const utilities = useAppSelector(getUtilities);
  const [credentials, setCredentials] = useState<{
    userData: any;
    isValid: boolean;
  }>({ userData: [], isValid: false });
  const { socket, sendWebSocketMessage, socketMessage } = useWebSocket();

  // check in first load of login if session is expired
  useEffect(() => {
    if (sessionData.message === "session expired") {
      setToastIcon("error");
      setToastMessage("Your session has expired. Kindly login again.");
      setShowToast(true);
    }
  }, [sessionData.message]);

  //lets do the validation...
  useEffect(() => {
    //retreive our states
    const { email, password } = state;
    //check if fields is not empty
    if (email !== "" && password !== "") {
      setHasInputError(false);
    } else {
      setHasInputError(true);
    }
  }, [state]);

  // when user press enter key
  const handleEnterKey = (e: any) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  // create our handler for login
  const handleLogin = async () => {
    //retreive our states
    const { email, password } = state;

    //declare paramters to pass
    const data = { email, password };

    //then disable the button
    setHasInputError(true);
    setIsLoadingAPI(true);

    //then call our custom function for calling POST API
    postLog_inAPI(data)
      .then((response:any) => {
        const userData: TokenModel = jwt_decode(response.data.items);
        setUserRoleContext(userData.role);
        // if credential exists
        if (response.statusCode === 202) {
          // then save it for both redux and local storage
          saveTokenToLocalStorage(response.data.items);
          setCredentials({
            userData,
            isValid: true,
          });

          if (utilities.commodityTypes.length === 0) {
            // check commodity types utility
            getCommodityTypes().then((data: any) =>
              dispatch(setUtilityCommodityTypes(data))
            );
          }
          // check commodity type methods utility
          if (utilities.commodityTypeMethods.length === 0) {
            getCommodityTypeMethods().then((data: any) =>
              dispatch(setUtilityCommodityTypeMethods(data))
            );
          }

          // display successfull message
          setToastMessage("Successfully Login.");
          setToastIcon("success");
          setShowToast(true);
          setIsLoadingAPI(false);

          setTimeout(() => {
            // cja: show transition page
            setIsShowTransitionPage(true);
            setTimeout(() => {
              // then redirect the user based on their roles
              switch (userData.role) {
                case 1:
                  navigate("/b/dashboard"); /* buyer dashboard */
                  localStorage.setItem("activeMenu", "buyer_dashboard");
                  setIsShowTransitionPage(false);
                  break;
                case 2:
                  navigate("/s/dashboard"); /* seller dashboard */
                  localStorage.setItem("activeMenu", "supplier_dashboard");
                  setIsShowTransitionPage(false);
                  break;
                case 6:
                  navigate("/l/dashboard"); /* logistic dashboard */
                  localStorage.setItem("activeMenu", "logistic_dashboard");
                  setIsShowTransitionPage(false);
                  break;
                case 7: 
                  navigate("/a/dashboard"); /* admin dashboard */
                  localStorage.setItem("activeMenu", "admin_dashboard");
                  setIsShowTransitionPage(false);
                  break;
                default:
                  break;
              }
            }, defaultModalTimeout());
          }, defaultSearchTimeout());
        } else {
          setToastIcon("error");
          setToastMessage(response.data.message);
          setTimeout(() => {
            setShowToast(true);
            setIsLoadingAPI(false);
            setState({
              email,
              password: "",
            });
          }, defaultModalTimeout());
        }
      })
      .catch((err) => {
        setToastIcon("error");
        setToastMessage("Invalid email or password");
        setIsLoadingAPI(false);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
  };

  // cja: if socket is open and user successfully logged in, this useEffect runs
  useEffect(() => {
    if (socket !== null) {
      const { userData, isValid } = credentials;

      if (socket?.readyState === WebSocket.OPEN && isValid) {
        const socketPayload = {
          user_id: userData._id,
        };

        sendWebSocketMessage("establishSocketConnection", socketPayload);
      }
    }
  }, [socket, credentials, sendWebSocketMessage]);

  // cja: this hook will handle the socket message
  useEffect(() => {
    if (socketMessage !== null) {
      const strData: string = socketMessage.toString();
      if (strData === "Success") {
        // do something here
      } else {
        const socketData = JSON.parse(strData);
        const { payload } = socketData;

        if (payload.key === "CONNECTION_ESTABLISHED") {
          // save connection details to redux
          dispatch(setSocketResponseData(socketData));
          dispatch(
            setSocketConnection({ isConnected: true, currentConnectionId: "" })
          );
        }
      }
    }
  }, [socketMessage, dispatch]);

  //get the input values
  const handleInputEvent = (value: string, name: string) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      {isShowTransitionPage === true ? (
        <TransitionComponent
          key={Math.random()}
          logo={
            <img
              className={`rounded-full mx-auto h-12 md:h-24`}
              src="https://res.cloudinary.com/doehyebmw/image/upload/v1681709063/dealogikal_2.0/resources/images/logo/app-logo-dark_wpiwgj.png"
              alt="Award"
            />
          }
          transition_text="Signing In. Please wait..."
        />
      ) : (
        <PageContentComponent
          content={{
            content: (
              <div className="flex flex-1 bg-dealogikal-300 h-screen">
                {/* toaster */}
                <ToasterComponent
                  isOpen={showToast}
                  label={message}
                  onClose={setShowToast}
                  type={toastIcon}
                />

                {/* main component */}
                <div className="relative hidden w-0 flex-1 lg:block">
                  <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://res.cloudinary.com/doehyebmw/image/upload/v1685954026/dealogikal_2.0/resources/images/big_img/login-img_i5mkco.jpg"
                    alt="Isometric Background"
                  />
                </div>

                <div className="flex flex-1 flex-col justify-center px-4 py-4 lg:py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                  <img
                    className="h-12 lg:h-16 w-auto mx-auto"
                    src="https://res.cloudinary.com/doehyebmw/image/upload/v1685676853/dealogikal_2.0/resources/images/logo/app-logo-white_uqm3sg.png"
                    alt="Dealogikal Logo"
                  />
                  <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-6 lg:py-12 shadow rounded-3xl sm:px-12">
                      <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                          <H2Header label="Sign in to your account" />
                        </div>

                        <div className="mt-4 lg:mt-10">
                          <div className="space-y-6">
                            <div>
                              <label
                                htmlFor="email"
                                className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                              >
                                Email address
                              </label>
                              <div className="mt-1 md:mt-2">
                                <input
                                  name="email"
                                  type="email"
                                  onChange={(e) =>
                                    handleInputEvent(e.target.value, "email")
                                  }
                                  className="block w-full rounded-full text-xs lg:text-sm border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="password"
                                className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                              >
                                Password
                              </label>

                              <div
                                className={`flex mt-1 md:mt-2 border ${
                                  isPasswordFocused
                                    ? "border-dealogikal-200"
                                    : "border-gray-300"
                                } shadow-sm rounded-full`}
                              >
                                <input
                                  name="password"
                                  type={
                                    !passwordIsVisible ? `password` : "text"
                                  }
                                  onKeyUp={handleEnterKey}
                                  onFocus={() => setIsPasswordFocused(true)}
                                  onBlur={() => setIsPasswordFocused(false)}
                                  onChange={(e) =>
                                    handleInputEvent(e.target.value, "password")
                                  }
                                  className="block w-full rounded-full text-xs lg:text-sm border-0 pl-6 py-3 placeholder:text-gray-400 focus:ring-0 focus:ring-inset sm:text-sm sm:leading-6"
                                />
                                {!passwordIsVisible ? (
                                  <EyeIcon
                                    className="h-4 lg:h-6 w-6 mx-3 my-auto text-gray-400 cursor-pointer"
                                    onClick={() =>
                                      setPasswordIsVisible(!passwordIsVisible)
                                    }
                                  />
                                ) : (
                                  <EyeSlashIcon
                                    className="h-4 lg:h-6 w-6 mx-3 my-auto text-gray-400 cursor-pointer"
                                    onClick={() =>
                                      setPasswordIsVisible(!passwordIsVisible)
                                    }
                                  />
                                )}
                              </div>
                            </div>

                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <input
                                  id="remember-me"
                                  name="remember-me"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-dealogikal-200 focus:ring-blue-400"
                                />
                                <label
                                  htmlFor="remember-me"
                                  className="ml-3 block text-xs lg:text-sm leading-6 text-gray-700"
                                >
                                  Remember me
                                </label>
                              </div>

                              <div className="text-xs lg:text-sm leading-6">
                                <ButtonComponent
                                  text="Forgot password?"
                                  utils="font-semibold text-dealogikal-200 sm:hover:text-blue-300"
                                  onClick={() => {
                                    setToastIcon("information");
                                    setToastMessage(
                                      "Sorry, this is feature is not yet available. Please bear with us."
                                    );
                                    setShowToast(true);
                                  }}
                                />
                              </div>
                            </div>

                            <div>
                              <ButtonComponent
                                text="Sign in"
                                icon={
                                  isLoadingAPI && (
                                    <Lottie
                                      className="md:w-5 w-5 h-auto"
                                      animationData={CircularLoading}
                                      loop={true}
                                    />
                                  )
                                }
                                utils={`${
                                  hasInputError
                                    ? `bg-blue-300`
                                    : `bg-dealogikal-100 sm:hover:bg-dealogikal-200`
                                } w-full text-white py-3 px-12 duration-500 rounded-full font-semibold text-xs lg:text-sm`}
                                onClick={() => handleLogin()}
                                disabled={hasInputError ? true : false}
                              />
                            </div>
                          </div>

                          <div className="mt-10">
                            <div className="relative">
                              <div
                                className="absolute inset-0 flex items-center"
                                aria-hidden="true"
                              >
                                <div className="w-full border-t border-gray-200" />
                              </div>
                              <div className="relative flex justify-center text-xs lg:text-sm font-medium leading-6">
                                <span className="bg-white px-6 text-gray-900">
                                  Or continue with
                                </span>
                              </div>
                            </div>

                            <div className="mt-6 grid grid-cols-2 gap-4">
                              <ButtonComponent
                                text="Gmail"
                                textUtils="text-xs lg:text-sm font-semibold leading-6"
                                utils="flex w-full sm:hover:bg-green-50 rounded-full py-2 lg:py-3 items-center justify-center gap-3 bg-white outline outline-1 outline-slate-300 px-3 text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                                icon={<GmailLogo />}
                                onClick={() => {
                                  setToastIcon("information");
                                  setToastMessage(
                                    "Sorry, this is feature is not yet available. Please bear with us."
                                  );
                                  setShowToast(true);
                                }}
                              />

                              <ButtonComponent
                                text="Facebook"
                                utils="flex w-full sm:hover:bg-blue-50 rounded-full py-2 lg:py-3 items-center justify-center gap-3 bg-white outline outline-1 outline-slate-300 px-3 text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                                textUtils="text-xs lg:text-sm font-semibold leading-6"
                                icon={<FacebookLogo />}
                                onClick={() => {
                                  setToastIcon("information");
                                  setToastMessage(
                                    "Sorry, this is feature is not yet available. Please bear with us."
                                  );
                                  setShowToast(true);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className="text-xs lg:text-sm leading-6 text-slate-700 mt-5 mx-auto">
                    <span className="mr-4">Don't have an account yet?</span>
                    <ButtonComponent
                      text="SIGN UP"
                      utils="font-semibold text-black sm:hover:text-slate-600"
                      onClick={() => {
                        setToastIcon("information");
                        setToastMessage(
                          "Sorry, this is feature is not yet available. Please bear with us."
                        );
                        setShowToast(true);
                      }}
                    />
                  </p>
                </div>
              </div>
            ),
          }}
        />
      )}
    </>
  );
};

export default LoginComponent;
