import { BuildingStorefrontIcon, TruckIcon } from "@heroicons/react/24/outline";
import { getFetch, patchFetch, postFetch, putFetch } from "./function";
import AuctionTemplateDetailsModel from "../../model/interface/AuctionTemplateDetailsModel";
import CreateAuctionTemplateDetailsModel from "../../model/interface/CreateAuctionTemplateDetailsModel";
import SingleOfferDataType from "../../model/types/SingleOfferDataType";

// fetch data from API to get the commodity types
export const getCommodities = (commodityTypeID: number = 2) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/commodity/get?commodity_type_id=${commodityTypeID}`,
			localStorage.getItem("_token") ?? ""
		)
			.then((res) => {
				if (res.statusCode === 200) {
					// return data
					resolve(res.data.items);
				}
			})
			.catch(() => {
				// return an error message
				reject("Error: Something went wrong in fetching commodities.");
			});
	});
};

// fetch data from API to get the commodity types
export const getCommodityTypes = () => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/commodity_type/get`,
			localStorage.getItem("_token") ?? ""
		)
			.then((res) => {
				if (res.statusCode === 200) {
					// return data
					resolve(res.data.items);
				}
			})
			.catch(() => {
				// return an error message
				reject("Error: Something went wrong in fetching commodity types.");
			});
	});
};

// fetch data from API to get the commodity type methods
export const getCommodityTypeMethods = () => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/commodity_type_method/get`,
			localStorage.getItem("_token") ?? ""
		)
			.then((res) => {
				if (res.statusCode === 200) {
					const methods = res.data.items.map(
						(i: { id: number; method_type: string; icon: string }) => ({
							...i,
							name: i.method_type,
							href: "/b/add_single_product",
						})
					);
					// return the data
					resolve(methods);
				}
			})
			.catch(() => {
				// return an error message
				reject(
					"Error: Something went wrong in fetching commodity type method."
				);
			});
	});
};

// cja: fetch product list from API
// this funciton will accept commodity_type_id(Petroluem, Non-petroluem, etc..) to display
export const getProductList = (
	_token: string,
	commodity_type_id: number,
	supplier_id?: string
) => {
	return new Promise((resolve, reject) => {
			
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/product/get?supplier_id=${
					supplier_id ?? ""
				}&commodity_type_id=${commodity_type_id}`,
			_token
		)
			.then((response: any) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				// return an error message
				reject("Error: Something went wrong in fetching product list.");
			});
	});
};

// fetch data from API to get the units
export const getUnits = () => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/unit/get`,
			localStorage.getItem("_token") ?? ""
		)
			.then((res) => {
				if (res.statusCode === 200) {
					// return the data
					resolve(res.data.items);
				}
			})
			.catch(() => {
				// return an error message
				reject("Error: Something went wrong in fetching units.");
			});
	});
};

// create a function for adding a product
export const addProduct = (params: {
	commodity_id: number;
	unit_id: number;
	supplier_id: number;
	stocks: number;
}) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/product/add`,
			params,
			localStorage.getItem("_token") ?? ""
		)
			.then((res) => {
				if (res.statusCode === 200) {
					// return the data
					resolve(res);
				}
			})
			.catch(() => {
				// return an error message
				reject("Error: Something went wrong while adding a new product.");
			});
	});
};

// cja: fetch offer list from api
export const getOfferList = (
	_token: string,
	status: number,
	supplier_id?: string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/get?supplier_id=${
				supplier_id ?? ""
			}&status=${status}`,
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				// return an error message
				reject("Error: Something went wrong in fetching offer list.");
			});
	});
};

//add offer confirmation list here
export const getOfferListConfirmation = (
	_token: string,
	order_status_id: number,
	supplier_id: string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/orders/get?supplier_id=${supplier_id}&order_status_id=${order_status_id}`,
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				reject(
					"Error: Something went wrong in fetching offer list confirmation."
				);
			});
	});
};

// export const getOfferListConfirmation = (
//   _token: string,
//   order_status_id: number,
//   supplier_id: string
// ) => {
//   return new Promise((resolve, reject) => {
//     // Simulating delay for API call
//     setTimeout(() => {
//       // Dummy data for offers
//       const dummyOffers = [
//         {
//           supplier_id: supplier_id,
//           order_status_id: order_status_id,
//           offer_name: "dummy offer 1",
//           requested_commodities:"aaaaaa",
//           requested_volume:2000,
//           delivery_date:"",
//           delivery_location:"ibo",
//           order_status:"for confirmation"
//           // Add more fields as needed
//         },
//         {
//           supplier_id: supplier_id,
//           order_status_id: order_status_id,
//           offer_name: "dummy offer 1",
//           requested_commodities:"bbbbbb",
//           requested_volume:2000,
//           delivery_date:"",
//           delivery_location:"mactan",
//           order_status:"for confirmation",
//           // Add more fields as needed
//         },
//         // Add more dummy offers as needed
//       ];

//       // Resolve the promise with dummy data
//       resolve(dummyOffers);
//     }, 1000); // Simulating delay of 1 second
//   });
// };

// cja: function in fetching the available shipping methods in the database
export const getShippingMethods = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/shipping_method/get`, _token)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				reject("Error: Something went wrong in fetching shipping methods.");
			});
	});
};

// cja: fetch municipalities function
export const getMunicipalitiesAPI = (_token: string, island_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/locations/municipality/get?island_id=${island_id}`,
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				reject("Error: Something went wrong in fetching municipalities.");
			});
	});
};

// cja: fetch philippine island function
export const getPhilippineIslandsAPI = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/locations/philippine_island/get`,
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				reject("Error: Something went wrong in fetching philippine islands.");
			});
	});
};

export const getQuotationDetailsAPI = (
	_token: string,
	quotation_id: number | string,
	user_role: number | string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/quotation/details/get?quotation_id=${quotation_id}&persona_type=${user_role}`,
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				reject("Error: Something went wrong in fetching quotation details.");
			});
	});
};

// cja: function in fetching the quotation details --> this is only a dummy api call function
export const getQuotationDetailss = (user_role: number, user_id: number) => {
	// dummy return data from get quotation details API
	const dummyQuotationDetailsSupplier = {
		quotation_details: {
			shipping_method: "Deliver",
			delivery_location: "Lapu-Lapu City",
			delivery_date: "September 30, 2023",
		},
		buyer: {
			buyer_id: 12,
			name: "Petrol K",
			logo_url:
				"https://res.cloudinary.com/doehyebmw/image/upload/v1692950680/dealogikal_2.0/resources/logo/petrol-k-logo_u61axw.png",
		},
		suppliers: [
			{
				supplier_id: 7,
				name: "Knottical Power",
				logo_url:
					"https://res.cloudinary.com/doehyebmw/image/upload/v1694141533/dealogikal_2.0/resources/logo/kpec-min_d5fgld.png",
				offers: [
					{
						offer_id: 1,
						offer_variation_id: 1,
						offer_name: "Cebu City Area Offer - Diesel",
						commodity_id: 13,
						commodity_name: "Diesel",
						commodity_icon:
							"https://res.cloudinary.com/dealogikal/image/upload/v1673944087/dealogikal-app/resources/icons/commodities/petroleum.png",
						min_volume: 2000,
						max_volume: 6000,
						requested_volume: 6000,
						price: 69.89,
					},
					{
						offer_id: 2,
						offer_variation_id: 2,
						offer_name: "Cebu City Area Offer - Premium Gasoline",
						commodity_id: 15,
						commodity_name: "Premium Gasoline",
						commodity_icon:
							"https://res.cloudinary.com/dealogikal/image/upload/v1673944087/dealogikal-app/resources/icons/commodities/petroleum.png",
						min_volume: 8000,
						max_volume: 15000,
						requested_volume: 6000,
						price: 57.98,
					},
				],
			},
			{
				supplier_id: 6,
				name: "PTT",
				logo_url:
					"https://res.cloudinary.com/doehyebmw/image/upload/v1694141533/dealogikal_2.0/resources/logo/ppt-min_hbgvcj.png",
				offers: [
					{
						offer_id: 1,
						offer_variation_id: 1,
						offer_name: "Cebu City Area Offer - Diesel",
						commodity_id: 13,
						commodity_name: "Diesel",
						commodity_icon:
							"https://res.cloudinary.com/dealogikal/image/upload/v1673944087/dealogikal-app/resources/icons/commodities/petroleum.png",
						min_volume: 2000,
						max_volume: 6000,
						requested_volume: 6000,
						price: 59.89,
					},
					{
						offer_id: 2,
						offer_variation_id: 2,
						offer_name: "Cebu City Area Offer - Premium Gasoline",
						commodity_id: 15,
						commodity_name: "Premium Gasoline",
						commodity_icon:
							"https://res.cloudinary.com/dealogikal/image/upload/v1673944087/dealogikal-app/resources/icons/commodities/petroleum.png",
						min_volume: 2000,
						max_volume: 12000,
						requested_volume: 6000,
						price: 59.89,
					},
				],
			},
		],
	};

	const dummyQuotationDetailsBuyer = {
		suppliers: [
			{
				supplier_id: 6,
				name: "PTT",
				logo_url:
					"https://res.cloudinary.com/doehyebmw/image/upload/v1694141533/dealogikal_2.0/resources/logo/ppt-min_hbgvcj.png",
				offers: [
					{
						commodity_name: "Automotive Diesel Oil",
						commodity_icon:
							"https://res.cloudinary.com/dealogikal/image/upload/v1673944087/dealogikal-app/resources/icons/commodities/petroleum.png",
						price: 79.5,
						min_volume: 2000,
						max_volume: 6000,
						requested_volume: 5000,
					},
					{
						commodity_name: "Premium Gasoline",
						commodity_icon:
							"https://res.cloudinary.com/dealogikal/image/upload/v1673944087/dealogikal-app/resources/icons/commodities/petroleum.png",
						price: 78.5,
						min_volume: 12000,
						max_volume: 18000,
						requested_volume: 8000,
					},
				],
			},
			{
				supplier_id: 7,
				name: "Knottical Power",
				logo_url:
					"https://res.cloudinary.com/doehyebmw/image/upload/v1694141533/dealogikal_2.0/resources/logo/kpec-min_d5fgld.png",
				offers: [
					{
						commodity_name: "Automotive Diesel Oil",
						commodity_icon:
							"https://res.cloudinary.com/dealogikal/image/upload/v1673944087/dealogikal-app/resources/icons/commodities/petroleum.png",

						price: 59.5,
						min_volume: 2000,
						max_volume: 6000,
						requested_volume: 5000,
					},
					{
						commodity_name: "Premium Gasoline",
						commodity_icon:
							"https://res.cloudinary.com/dealogikal/image/upload/v1673944087/dealogikal-app/resources/icons/commodities/petroleum.png",

						price: 68.5,
						min_volume: 12000,
						max_volume: 18000,
						requested_volume: 8000,
					},
				],
			},
		],
		quotation_details: {
			shipping_method: "Deliver",
			delivery_location: "Lapu-Lapu City",
			delivery_date: "September 30, 2023",
		},
	};

	return new Promise((resolve, reject) => {
		if (user_role === 2) {
			let updatedCardData: any[] = [
				{
					name: dummyQuotationDetailsSupplier.buyer.name,
					icon: BuildingStorefrontIcon,
					role: "Buyer",
					imageUrl: dummyQuotationDetailsSupplier.buyer.logo_url,
				},
				{
					name: "FTAT Logistics",
					icon: TruckIcon,
					role: "Logistic",
					imageUrl:
						"https://res.cloudinary.com/doehyebmw/image/upload/v1692950374/dealogikal_2.0/resources/logo/trucking-logo_qesgia.png",
				},
				{
					name:
						dummyQuotationDetailsSupplier.suppliers[0].supplier_id === user_id
							? "You"
							: dummyQuotationDetailsSupplier.suppliers[0].name,
					icon: TruckIcon,
					role: "Supplier",
					imageUrl: dummyQuotationDetailsSupplier.suppliers[0].logo_url,
				},
			];

			resolve({
				card_data: updatedCardData,
				quotation_details: dummyQuotationDetailsSupplier,
			});
		} else if (user_role === 1) {
			let updatedCardData: any[] = [
				{
					name: dummyQuotationDetailsBuyer.suppliers[0].name,
					icon: TruckIcon,
					role: "Supplier",
					imageUrl: dummyQuotationDetailsBuyer.suppliers[0].logo_url,
				},
				{
					name: "FTAT Logistics",
					icon: TruckIcon,
					role: "Logistic",
					imageUrl:
						"https://res.cloudinary.com/doehyebmw/image/upload/v1692950374/dealogikal_2.0/resources/logo/trucking-logo_qesgia.png",
				},
			];

			resolve({
				card_data: updatedCardData,
				quotation_details: dummyQuotationDetailsBuyer,
			});
		}
	});
};

// create an API call for getting the quotations from a buyer
export const getQuotationsFromBuyer = (
	_token: string,
	buyer_id: number | string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/quotation/get?buyer_id=${buyer_id ?? ""}`,
			_token
		)
			.then((response: any) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				// return an error message
				reject(
					"Error: Something went wrong in fetching quotation list from a buyer."
				);
			});
	});
};

export const getReverseAuctionDetailsAPI = (
	_token: string,
	reverse_auction_id: number | string,
	user_role: number | string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction/dummy_details/get?reverse_auction_id=${reverse_auction_id}&persona_type=${user_role}`,
			// TODO: update the reverse auction API url
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				reject("Error: Something went wrong in fetching quotation details.");
			});
	});
};

export const getReverseAuctionListAPI = (
	_token: string,
	buyer_id: number | string,
	status: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction/get?buyer_id=${buyer_id}&status=${status}`,
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response);
				}
			})
			.catch(() => {
				reject("Error: Something went wrong in fetching reverse auction list.");
			});
	});
};

export const getReverseAuctionTemplateDetailsAPI = (
	_token: string,
	reverse_auction_template_id: number | string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction_template/details/get?reverse_auction_template_id=${reverse_auction_template_id}`,
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response);
				}
			})
			.catch(() => {
				reject(
					"Error: Something went wrong in fetching reverse auction template details."
				);
			});
	});
};

export const getUpdatedRemainingReverseAuctionTime = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction/remaining_time/get`,
			_token
		)
			.then((response) => {
				if (response.statusCode === 200) {
					resolve(response.data.items);
				}
			})
			.catch(() => {
				reject(
					"Error: Something went wrong in fetching reverse auction updated time."
				);
			});
	});
};

export const updateReverseAuctionTemplateDetails = (
	_token: string,
	updated_auction_template_details: AuctionTemplateDetailsModel
) => {
	return new Promise((resolve, reject) => {
		putFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction_template/update`,
			updated_auction_template_details,
			_token
		)
			.then((response: any) => {
				resolve(response);
			})
			.catch(() => {
				reject(
					"Error: Something went wrong in updating reverse auction template details."
				);
			});
	});
};

export const addNewReverseAuctionTemplateAPI = (
	_token: string,
	createAuctionTemplateData: CreateAuctionTemplateDetailsModel
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction_template/add`,
			createAuctionTemplateData,
			_token
		)
			.then((response) => {
				resolve(response);
			})
			.catch(() => {
				reject(
					"Error: Something went wrong in adding new reverse auction template."
				);
			});
	});
};

export const rescheduleExpiredReverseAuction = (
	_token: string,
	data: { reverse_auction_id: number | string; delivery_date: string }
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction/reschedule`,
			data,
			_token
		)
			.then((response) => {
				resolve(response);
			})
			.catch(() =>
				reject("Error: Something went wrong in rescheduling reverse auction")
			);
	});
};

export const getDayOfWeek = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction/day_of_week/get`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in fetching day of the week.")
			);
	});
};

export const getReverseAuctionOpeningTime = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction/opening_time/get`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in fetching opening time.")
			);
	});
};

export const getBookWarningReferenceAPI = (
	_token: string,
	reference_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reference/get?reference_id=${reference_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in fetching reference.")
			);
	});
};

export const getOfferDetailsVariations = (
	_token: string,
	offer_id: string,
	supplier_id: string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/details/offer_and_variations/get?offer_id=${offer_id}&supplier_id=${supplier_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in fetching offer details.")
			);
	});
};

export const updateOfferVariationPrice = (
	_token: string,
	data: {
		reverse_auction_id: string;
		offer_variation_id: number;
		old_price: string;
		new_price: string;
	}
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/update/price`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in updating offer variation price.")
			);
	});
};

export const updateOfferDetails = (
	_token: string,
	data: {
		offer_id: string;
		offer_name: string;
		shipping_method_id: string;
		delivery_location_id: string;
		supplier_id: string;
	}
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/update/details`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in updating offer details.")
			);
	});
};

export const updateOfferVariations = (
	_token: string,
	data: {
		offer_id: string;
		variation_items: any;
	}
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/update/variations`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in updating offer variations.")
			);
	});
};

export const unpublishOffer = (
	_token: string,
	data: { offer_id: string; status: number }
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/update/publish`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in unpublishing offer.")
			);
	});
};
// Janm
// fetch update product
export const updateProduct = (
	_token: string,
	data: { product_id: string; supplier_id: string; stocks: number }
) => {
	return new Promise((resolve, reject) => {
		patchFetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/product/update`, data, _token)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in updating product"));
	});
};

// get product id
export const getProductDetails = (
	_token: string,
	supplier_id: number | string,
	product_id: number | string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/product/get?supplier_id = ${supplier_id}&product_id=${product_id}`,
			_token
		)
			.then((response: any) => resolve(response))
			.catch(() => {
				// return an error message
				reject("Error: Something went wrong in fetching product details.");
			});
	});
};

// get add truck
export const addVehicle = (
	_token: string,
	data: {
		commodities: number[];
		vehicle_name: string;
		vehicle_type_id: string;
		vehicle_wheel_id: string;
		commodity_type_id: string;
		compartments: string;
		plate_number: string;
		volume: string;
		manufacture_id: string;
		supplier_id: string;
		user_id: string;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/vehicle/add`, data, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching product details");
			});
	});
};

// get vehicle type
export const getVehicleTypeAPI = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/vehicle_type/get`, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching product details");
			});
	});
};

// get vehicle cargo
export const getVehicleCargoAPI = (
	_token: string,
	commodity_id: number,
	vehicle_types: string | undefined
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/vehicle/cargos/get?commodity_type_id=${commodity_id}&vehicle_type_id=${vehicle_types}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching product details");
			});
	});
};

// get vehicle wheel
export const getVehicleWheelAPI = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/vehicle_wheel/get`, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching product details");
			});
	});
};

// get vehicle manufacture
export const getVehicleManufactureAPI = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/vehicle_manufacture/get`, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching product details");
			});
	});
};

// get vehicle compartments
export const getVehicleCompartmentAPI = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/compartment/get`, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching product details");
			});
	});
};

// get all vehicles
export const getAllAddTruckAPI = (_token: string, supplier_id: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/vehicle/get?supplier_id=${supplier_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching product details");
			});
	});
};

// get all vehicles name
export const getVehicleNames = (_token: string, user_id: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/vehicle/vehicle_names/get?user_id=${user_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching vehicle names");
			});
	});
};

export const addRateAPI = (
	_token: string,
	data: {
		rate_name: string;
		island_id: number;
		vehicle_id: number[];
		rates: {
			location: string;
			direction: string;
			km: number;
			freight: string;
			pump: string;
			tax: string;
			total: string;
			new_rate: string;
		};
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/rate`, data, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching vehicle names");
			});
	});
};

// get rates
export const getRateListAPI = (_token: string, user_id: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/rate?user_id=${ user_id }`, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching rate list");
			});
	});
};

// get suppliers list for accredit
export const getSupplierAccreditAPI = (
	_token: string,
	commodity_ids: string,
	shipping_method_id: string,
	delivery_location_id: string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction_template/suppliers/get?commodity_ids=${commodity_ids}&shipping_method_id=${shipping_method_id}&delivery_location_id=${delivery_location_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching suppliers list");
			});
	});
};

export const getAccreditationAPI = (
	_token: string,
	user_id: string | number,
	status?: string | number
) => {
	return new Promise((resolve, reject) => {
		//construct the based URL
		let url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/accreditation?user_id=${user_id}`;
		//if status is passed, add it to the URL
		if (status !== undefined) {
			url += `&status=${status}`;
		} else {
			if (status === "approved") {
				url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/accreditation?user_id=${user_id}&status=approved`;
			} else {
			}
		}

		// Use the getFetch function to get the data
		getFetch(url, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching acreditation");
			});
	});
};

export const getPreOrderAPI = (
	_token: string,
	user_id: string | number,
	status_id?: number,
	page_no?: number,
	page_size?: number
) => {
	return new Promise((resolve, reject) => {
		// //construct the based URL
		// let url = `${process.env.REACT_APP_API_URL}/vehicle/pre_order/get`;
		// //if user_id is passed, add it to the URL
		// if (user_id !== undefined) {
		// 	url += `?user_id=${user_id}`;
		// }
		// //if status_id is passed, add it to the URL
		// if (status_id !== undefined) {
		// 	url += `&status_id=${status_id}`;
		// }
		// Use the getFetch function to get the data
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/vehicle/pre_order/get?user_id=${user_id}&status_id=${status_id}&page_no=${page_no}&page_size=${page_size}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching acreditation");
			});
	});
};

export const postAccreditationAPI = (
	_token: string,
	data: {
		user_id: string | number;
		vehicle_id: number;
		status?: string | number;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/accreditation`, data, _token)
			.then((response) => {
				if (response.statusCode === 200) {
					// return the data
					resolve(response);
				}
			})
			.catch(() => {
				reject("Error: Something went wrong in fetching accreditatio");
			});
	});
};

//get rates details
export const getRateDetailsAPI = (_token: string, rate_details_id: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/rate/list?rate_details_id=${rate_details_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching rate list");
			});
	});
};

export const getAllAddTruckDetailsAPI = (
	_token: string,
	rate_details_id: string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/rate/details?rate_details_id=${rate_details_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching product details");
			});
	});
};

// get notifications api
export const getNotificationsAPI = (_token: string, user_id: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/notifications/get?user_id=${user_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong on fetching notifications");
			});
	});
};

// update notifications api
export const updateNotificationsAPI = (
	_token: string,
	data: {
		notification_id: number;
		status: string;
	}
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/notifications/update`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in updating unread notifications")
			);
	});
};

//get RateOrderList API
export const getRateOrderListAPI = (
	_token: string,
	rate_details_id: string | number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/rate_details/order_list/get?rate_details_id=${rate_details_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching rate list");
			});
	});
};

// cja: get offer bookings API
export const checkBookingsAPI = (
	_token: string,
	user_role_id: number,
	reverse_auction_id?: number,
	user_id?: number
) => {
	let requestParam = ``;

	if (user_role_id === 1) {
		requestParam = `user_role_id=${user_role_id}${
			reverse_auction_id !== undefined
				? `&reverse_auction_id=${reverse_auction_id}`
				: ``
		} `;
	} else if (user_role_id === 2) {
		requestParam = `user_role_id=${user_role_id}${
			reverse_auction_id !== undefined
				? `&reverse_auction_id=${reverse_auction_id}`
				: `&user_id=${user_id}`
		} `;
	} else if (user_role_id === 6) {
		requestParam = `user_role_id=${user_role_id}${
			reverse_auction_id !== undefined
				? `&reverse_auction_id=${reverse_auction_id}`
				: `&user_id=${user_id}`
		} `;
	}

	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer_bookings/get?${requestParam}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in checking the bookings");
			});
	});
};

//get RateConfirmation API
export const getRateConfirmationAPI = (_token: string, user_id: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/rate/confirmation/list/get?user_id=${user_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject(
					"Error: Something went wrong in fetching rate confirmation list"
				);
			});
	});
};

export const getOfferDetailsLogisticList = (
	_token: string,
	offer_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/details/logistics/get?offer_id=${offer_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() =>
				reject("Error: Something went wrong in fetching list of logistics")
			);
	});
};
// get suppliers list for accredit
export const getAccreditSupplierAPI = (
	_token: string,
	data: {
		commodities: number[];
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/accreditation/suppliers/get`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching suppliers list");
			});
	});
};

export const getQuotationAPI = (_token: string, status_id: number) => {
	return new Promise((resolve, reject) => {
		let params = status_id ? "status_id=" + status_id : "";
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/quote?${params}`, _token)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

export const socketConnectionCleanUp = (
	_token: string,
	data: {
		user_id: number;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/cleanup`, data, _token)
			.then((response) => resolve(response))
			.catch(() => {
				reject(
					"Error: Something went wrong in sending a request for the cleanup function"
				);
			});
	});
};

// create a function for adding a product
export const uploadImageReceipt = (
	_token: string,
	data: {
		offer_bookings_id: number;
		deposite_slip_url: string;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/payment/details/save`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject(
					"Error: Something went wrong in sending a request for the cleanup function"
				);
			});
	});
};

// payment verification
export const postPaymentVerification = (
	_token: string,
	params: {
		offer_booking_id: number;
		payment_details_id: number;
	}
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/payment/update_status`,
			params,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				// return an error message
				reject("Error: Something went wrong while verifying payment");
			});
	});
};

export const getPaymentListAPI = (_token: string, order_status_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/payment/get?order_status_id=${order_status_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// get disbursement details
export const getSupplierAndLogisticDetailsAPI = (
	_token: string,
	offer_booking_id: number,
	order_status_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/payment/get?offer_booking_id=${offer_booking_id}&order_status_id=${order_status_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// api for disbursement
export const uploadImageDisbursement = (
	_token: string,
	data: {
		offer_bookings_id: number;
		user_id: number;
		disbursement_slip_url: string;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/disbursement/save`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject(
					"Error: Something went wrong in sending a request for the cleanup function"
				);
			});
	});
};

// api for Buyer Order Detail
export const getBuyerOrderDetailsAPI = (
	_token: string,
	offer_booking_id: number,
	order_status_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/payment/get?offer_booking_id=${offer_booking_id}&order_status_id=${order_status_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch((error) => {
				console.error("API Request Error:", error);
				reject("Error: Something went wrong in sending a request");
			});
	});
};

export const getCloseOrdersAPI = (
	_token: string,
	role_id: number,
	user_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/disbursement/get?role_id=${role_id}&user_id=${user_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

//API for Image upload for Sales Order List
export const uploadImageSalesOrder = (
	_token: string,
	data: {
		order_details_id: number;
		offer_bookings_id: number;
		sales_order_number: string;
		sales_order_url: string;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/sales_order/upload`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching the data");
			});
	});
};

// api for disbursement
export const verificationAPI = (
	_token: string,
	data: {
		status_id: number;
		disbursement_details_id: number;
		note: string;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/disbursement/status`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject(
					"Error: Something went wrong in sending a request for the cleanup function"
				);
			});
	});
};

export const getSalesOrderAPI = (_token: string, role_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/sales_order/get?role_id=${role_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

export const updateSalesOrderAPI = (
	_token: string,
	data: {
		order_details_id: number;
		status_id: number;
		offer_bookings_id: number;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/sales_order/update`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// fetching api for invoice list
export const getInvoiceListAPI = (_token: string, buyer_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/invoice/get?buyer_id=${buyer_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

//
export const getInvoiceListDetailsAPI = (
	_token: string,
	invoice_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/invoice/details?invoice_id=${invoice_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// Driver list
export const getDriverListAPI = (_token: string, logistic_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/drivers/get?logistic_id=${logistic_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// add Driver Info
export const addDriverInfoAPI = (
	_token: string,
	data: {
		firstname: string;
		middlename: string;
		lastname: string;
		license_number: string;
		license_url: string;
		logistic_id: number;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/drivers/add`, data, _token)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

export const getATW_API = (_token: string, atw_id?: number) => {
	const atwParam = atw_id ? `?atw_id=${atw_id}` : ``;
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/authorization/supplier/get${atwParam}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// Get ATL (Logistic/Shipping)
export const getATL_list_API = (_token: string, logistic_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/authorization/logistic/get?logistic_id=${logistic_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

export const getATLDetailsAPI = (
	_token: string,
	logistic_id: number,
	atl_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/authorization/logistic/get?logistic_id=${logistic_id}&atl_id=${atl_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// api for confirming atl
export const atlConfirmationAPI = (
	_token: string,
	data: { atw_id: number; offer_booking_id: number; driver_id: number }
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/authorization/logistic/confirm_to_load`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in updating product"));
	});
};

// fetching api for invoice list
export const getIntransitListAPI = (_token: string, buyer_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/orders/get?buyer_id=${buyer_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// fetching to ship list
export const getToShipListsAPI = (_token: string, logistic_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/to_ship/get?logistic_id=${logistic_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

//fetching to ship details
export const getToShipDetailsAPI = (
	_token: string,
	logistic_id: number,
	atl_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/to_ship/get?logistic_id=${logistic_id}&atl_id=${atl_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// fetching to In-Transit Details
export const getInTransitDetailsAPI = (
	_token: string,
	offer_booking_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/orders/get_details?offer_bookings_id=${offer_booking_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// fetching dashbord overview
export const getOverviewDashboardAPI = (
	_token: string,
	logistic_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/overview/logistic/get?logistic_id=${logistic_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// patch confirm to deliver
export const updateConfirmToDeliverAPI = (
	_token: string,
	data: {
		offer_bookings_id: number;
	}
) => {
	return new Promise((resolve, reject) => {
		patchFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/to_ship/confirm_to_deliver`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in updating Delivery"));
	});
};

//fetching to delivery list
export const getDeliveryListAPI = (_token: string, logistic_id: number) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/deliveries/get?logistic_id=${logistic_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

//fetching to delivery details list
export const getDeliveryDetailsAPI = (
	_token: string,
	logistic_id: number,
	delivery_receipt_id: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/deliveries/get?logistic_id=${logistic_id}&delivery_receipt_id=${delivery_receipt_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// post newly selected supplier
export const postSelectedSupplierAPI = (
	_token: string,
	data: {
		reverse_auction_id: number;
		selected_offer: number;
		selected_supplier?: number;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction/selected_supplier/add`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => {
				reject("Error: Something went wrong in fetching accreditatio");
			});
	});
};

// get shipment for display on the admin side
export const getShipmentForAdminAPI = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/shipment`, _token)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// get shipment for display on the admin side
export const getDeliveryForAdminAPI = (
	_token: string,
	order_status: number
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/shipment?status_id=${order_status}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

export const getUpdatedPricesAPI = (
	_token: string,
	offer_variation_id: number,
	reverse_auction_id: string
) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/updated_price/get?offer_variation_id=${offer_variation_id}&reverse_auction_id=${reverse_auction_id}`,
			_token
		)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the price"));
	});
};

// get shipment for display on the admin side
export const getATLForAdminAPI = (_token: string) => {
	return new Promise((resolve, reject) => {
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/authorization/admin`, _token)
			.then((response) => resolve(response))
			.catch(() => reject("Error: Something went wrong in fetching the data"));
	});
};

// post email if triggered the supplier and logistic and will send an email
export const postBookingEmailSender = (
	_token: string,
	data: {
		to:string;
		from:string;
		subject:string;
		htmlContent:string;
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/send_email`,data,_token
	)
		.then((response) => resolve(response))
		.catch(()=>{
			reject("Error: Something went wrong in this Emailer");
		});
	});
};

// Rey: to fetch the auction template list 
export const getAuctionTemplateListAPI = (
	_token: string,
    user_id:number,
) => {
	return new Promise((resolve, reject)=>{
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction_template/list/get?buyer_id=${user_id}`,
			_token
		)
			.then((response)=>resolve(response))
			.catch(()=>reject("Error: Something went wrong in fetching the reverse auction template"))
	});
}

// Rey: to fetch the get reverse auction template supplier list with logo
export const getAuctionTemplateSupplierListwithLogoAPI = (
	_token: string,
	commodity_ids: string[],
	shippingMethod_id: string,
	delivery_location_id:string,
) => {
	return new Promise((resolve, reject)=>{
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction_template/suppliers/get?commodity_ids=${commodity_ids}&shipping_method_id=${shippingMethod_id}&delivery_location_id=${delivery_location_id}`,
			_token
		)
			.then((response)=>resolve(response))
			.catch(()=>reject("Error: Something went wrong in fetching the reverse auction template supplier list"))
	})
}

// Rey: post additional reverse auction
export const postAddReverseAuctionAPI = (
	_token: string,
	data:{
		commodities:string[],
		shipping_method_id: number,
		island_id: number,
		municipality_id: number,
		vehicle_id: string[],
		delivery_location_details:string,
		delivery_date: string,
		buyer_id:number
	}
) =>{
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction/add`,
			data,
			_token
		)
			.then((response) => resolve(response))
			.catch(()=>{reject("Error:Something went wrong in adding reverse auction ")})
	});
}

//Rey: get a list of reverse auction templates based on the provided buyer ID
export const getReverseAuctionTemplateListAPI = (
	_token: string,
	user_id: number,
) => {
	return new Promise((resolve,reject)=>{
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/reverse_auction_template/list/get?buyer_id=${user_id}`,
			_token
		)
			.then((response)=>resolve(response))
			.catch(()=>{reject("Error:Something went wrong in fetching reverse auction template list")})
	})
}

//Rey: post log-in data for user to log-in
export const postLog_inAPI = (
	data: {
		email: string,
		password: string,
	}
) => {
	return new Promise((resolve, reject) => {
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/login`, data)
			.then((response)=>resolve(response))
			.catch(()=>{
				reject("Error: Something went wrong in submiting log-in data");
			});
	});
}

//Rey: post additional new offer
export const postOfferAPI = (
	_token: string,
	data:SingleOfferDataType
) => {
	return new Promise((resolve,reject)=>{
		postFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/add`,
			data,
			_token
		  )
		  	.then((response)=>resolve(response))
			.catch(()=>reject("Error: Something went wrong in adding new offer."))
	});
}

//Rey: get the details of a specific offer by providing the offer ID
export const getOfferDetailsListAPI = (
	_token:string,
	offer_id:string,
) => {
	return new Promise((resolve,reject)=>{
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/details/get?offer_id=${offer_id}`,
			_token
		)
			.then((response)=>resolve(response))
			.catch(()=>reject("Error: Something went wrong in fetching offer."))
	})
}

//Rey: get the details list offers based on offer ID and supplier ID parameter
export const getOfferDetailsListSupplierAPI = (
	_token:string,
	offer_id:string,
	supplier_id:string,
) => {
	return new Promise((resolve,reject)=>{
		getFetch(
			`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENVIRONMENT}/offer/details/list/get?offer_id=${offer_id}&supplier_id=${supplier_id}`,
			_token
		)
			.then((response)=>resolve(response))
			.catch(()=>reject("Error: Something went wrong in fetching offer details list for supplier."))
	});
}
