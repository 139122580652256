import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import MainConsoleLayoutComponent from "../MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import {
  classNames,
  countDecimalPoints,
  formatNumberWithComma,
  formatToReadableDate,
  generateKey,
  getOperationLeadTime,
  getTokenFromLocalStorage,
  getTotalNumberOfDaysInMonth,
  manageSteps,
  pesoSign,
} from "../../../redux/functions/function";
import StepType from "../../../model/types/StepType";
import BreadcrumbContainerComponent from "../BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import StepsComponent from "../StepsComponent/StepsComponent";
import BreadcrumbComponent from "../../templates/BreadcrumbComponent/BreadcrumbComp";
import TabComponent from "../TabComponent/TabComponent";
import DisclosureComponent from "../DisclosureComponent/DisclosureComponent";
import CardComponent from "../CardComponent/CardComponent";
import StaticTableComponent from "../TableComponent/StaticTableComponent";
import { PeopleCardModel } from "../../../model/interface/PeopleCardModel";
import { TokenModel } from "../../../model/interface/TokenModel";
import MetaType from "../../../model/types/MetaType";
import Lottie from "lottie-react";
import PageLoading from "../../../lottie/Animation - 1719287159906.json";
import Refreshing from "../../../lottie/refresh_animation.json";
import Skeleton from "react-loading-skeleton";
import CircularLoading from "../../../lottie/circular_loading_theme_1.json";
import "react-loading-skeleton/dist/skeleton.css";
import {
  CalendarDaysIcon,
  CalendarIcon,
  ExclamationTriangleIcon,
  MapPinIcon,
  TruckIcon,
  XMarkIcon,
  ExclamationCircleIcon,
  Square3Stack3DIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import MetaComponent from "../MetaComponent/MetaComponent";
import ModalComponent from "../ModalComponent/ModalComponent";
import SlideOverComponent from "../SlideOverComponent/SlideOverComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import ReverseAuctionBannerComponent from "../ReverseAuctionBannerComponent/ReverseAuctionBannerComponent";
import {
  checkBookingsAPI,
  getBookWarningReferenceAPI,
  getDayOfWeek,
  getReverseAuctionOpeningTime,
  getUpdatedPricesAPI,
  getUpdatedRemainingReverseAuctionTime,
  postBookingEmailSender,
  postSelectedSupplierAPI,
  rescheduleExpiredReverseAuction,
  updateOfferVariationPrice,
} from "../../../redux/functions/API";
import ComboboxComponent from "../ComboboxComponent/ComboboxComponent";
import CalendarComponent from "../CalendarComponent/CalendarComponent";
import EnabledDatesModel from "../../../model/interface/EnabledDatesModel";
import ClosedAuctionStateComponent from "../ClosedAuctionStateComponent/ClosedAuctionStateComponent";
import ToasterComponent from "../ToasterComponent/ToasterComponent";
import { useNavigate } from "react-router-dom";
import EnabledPriceEditorType from "../../../model/types/EnabledPriceEditorType";
import ButtonWithTimerComponent from "../ButtonComponent/ButtonWithTimerComponent";
import { useWebSocket } from "../../../services/ws/WebSocketService";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
  getOfferBookingId,
  getSocketResponseData,
  setOfferBookingId,
  getOfferDetails,
  setReverseAuctionDetails,
  getOfferOrderList,
} from "../../../redux/functions/storageSlice";
import BookingConfirmationEmailSenderComponent from "../EmailSenderComponent/BookingConfirmationEmailSenderComponent";
import { renderToString } from "react-dom/server";
import BookingNowSupplierEmailSenderComponent from "../EmailSenderComponent/BookingNowSupplierEmailSenderComponent";
import BookingNowLogisticsEmailSenderComponent from "../EmailSenderComponent/BookingNowLogisticsEmailSenderComponent";

interface ReverseAuctionCompModel {
  user_data: TokenModel;
  card_data: PeopleCardModel[];
  reverse_auction_id: number | string;
  reverse_auction_details: any;
  refreshHandler: () => void;
  isRefreshing: boolean;
  updateCardData?: (updatedCardData: PeopleCardModel[]) => void;
}

const ReverseAuctionComponent = (props: ReverseAuctionCompModel) => {
  const { reverse_auction_data, buyer_data, logistic_data, supplier_data } =
    props.reverse_auction_details;
  const { updateCardData } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  // declare states here...
  const [otherSuppliersModalIsOpen, setOtherSuppliersModalIsOpen] =
    useState(false);
  const [otherLogisticsModalIsOpen, setOtherLogisticsModalIsOpen] =
    useState(false);
  const [selectedSupplierIndex, setSelectedSupplierIndex] = useState<number>(0);
  const [selectedLogisticIndex, setSelectedLogisticIndex] = useState<number>(0);

  const [supplierOffer, setSupplierOffer] = useState<any>([]);
  const [slideOver, setSlideOver] = useState<boolean>(false);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const [isDelayComplete, setIsDelayComplete] = useState<boolean>(false);
  const [isActiveQuotationFetching, setisActiveQuotationFetching] =
    useState(true);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [triggerEffect, setTriggerEffect] = useState(false); // New state variable
  // This is commented for now -> view the associated useEffect for opening the slider menu
  const [, setIsFetchingPaymentDetails] = useState<boolean>(false);

  const [processingFee, setProcessingFee] = useState<number>(0);
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [totalTransactionCost, setTotalTransactionCost] = useState<number>(0);

  const { refreshHandler } = props;
  const [remainingTimeInSeconds, setRemainingTimeInSeconds] =
    useState<number>(0);
  const [isRemainingTimeLoading, setIsRemainingTimeLoading] =
    useState<boolean>(true);

  //const [supplierDetails, setSupplierDetails] = useState<any[]>([]);

  const supplierDetails = useMemo(() => {
    const details = Array.isArray(supplier_data?.supplier_details)
      ? supplier_data?.supplier_details
      : [];

    return details;
  }, [supplier_data?.supplier_details]);

  const selectedSupplier = supplierDetails.find(
    (supplier: any) => supplier.is_selected
  );

  const [updatedOffers, setUpdatedOffers] = useState<any[]>([]);

  const orderList = useAppSelector(getOfferOrderList);
  const commodityTableData = selectedSupplier
    ? selectedSupplier?.offers // Use offers of the selected supplier
    : supplier_data?.cheapestSupplier?.offers; // Fallback to cheapestSupplier

  const accreditedLogistics =
    logistic_data?.logistic_details?.filter(
      (logistic: any) =>
        logistic.accreditated_by?.[0] === selectedSupplier?.supplier_id
    ) || [];

  useEffect(() => {
    // Only trigger the API call if commodityTableData exists and has data
    if (commodityTableData && commodityTableData.length > 0) {
      const updatePrices = async () => {
        const updated = await Promise.all(
          commodityTableData.map(async (offer: any) => {
            try {
              // Call getUpdatedPricesAPI for each offer
              const response: any = await getUpdatedPricesAPI(
                _token, // pass your _token here
                offer.offer_variation_id, // Use offer_variation_id for each offer
                String(props.reverse_auction_id) // Use reverse_auction_id from props
              );

              // Check if API returned the updated price
              if (response?.data?.items?.length > 0) {
                const updatedPrice = response.data.items[0].updated_price;

                // Return the offer with the updated price
                return {
                  ...offer,
                  price: updatedPrice, // Update the price with the new price
                };
              }
            } catch (error) {
              console.error(
                `Failed to fetch updated price for offer variation ${offer.offer_variation_id}`,
                error
              );
            }

            // If no updated price or error occurred, return the original offer
            return offer;
          })
        );

        // Store the updated offers in the component state
        setUpdatedOffers(updated);
      };

      // Call the updatePrices function once
      updatePrices();
    }
  }, [commodityTableData, _token, props.reverse_auction_id]);

  // cja: this state flag will be triggered once, so by default it is set to true.
  const [isRefreshingExpired, setIsRefreshingExpired] = useState(true);

  // useEffect(() => {
  // 	if (offerBookingIds === undefined) {
  // 		setisActiveQuotationFetching(true);
  // 	}
  // }, [offerBookingIds]);
  /*
    Active Quotation Status ID Legend:
      1 - In Progress
      2 - Waiting
      3 - Expired
      4 - Completed
  */
  const [auctionStatus, setAuctionStatus] = useState<number>(1);
  const [message, setToastMessage] = useState("");
  const [toastIcon, setToastIcon] = useState("success");
  const [showToast, setShowToast] = useState(false);
  const [tomorrowDayOfWeek, setTomorrowDayOfWeek] = useState<number>(-1);
  const [isLoadingTomorrowDayofWeek, setIsLoadingTomorrowDayofWeek] =
    useState<boolean>(true);

  // this flag local state will handle the timer to pause so that component rendering will be also paused
  const [isTimerPaused, setIsTimerPaused] = useState<boolean>(false);

  // redux states
  const socketResponseData = useAppSelector(getSocketResponseData);
  const offerBookingId = useAppSelector(getOfferBookingId);
  const offerDetails = useAppSelector(getOfferDetails);

  useEffect(() => {
    if (tomorrowDayOfWeek === -1 && isLoadingTomorrowDayofWeek) {
      getDayOfWeek(_token)
        .then((response: any) => {
          setIsLoadingTomorrowDayofWeek(false);
          let numericDayOfWeek: number = Number(response.data.items.day_num);
          /*
            1 - Monday
            2 - Tuesday
            3 - Wednesday
            4 - Thursday
            5 - Friday
            6 - Saturday
            7 - Sunday
          */
          let tomorrowNumericDayOfWeek: number = 0;
          if (numericDayOfWeek === 7) {
            tomorrowNumericDayOfWeek = 1;
          } else {
            numericDayOfWeek = numericDayOfWeek + 1;
            tomorrowNumericDayOfWeek = numericDayOfWeek;
          }
          setTomorrowDayOfWeek(tomorrowNumericDayOfWeek);
        })
        .catch((err) => console.log(err));
    }
  }, [tomorrowDayOfWeek, isLoadingTomorrowDayofWeek, _token]);

  // Function to poll for reverseAuctionStatus
  const pollReverseAuctionStatus = useCallback(() => {
    const interval = setInterval(() => {
      let reverseAuctionStatus =
        reverse_auction_data?.reverse_auction_status_id;

      if (reverseAuctionStatus !== 0) {
        clearInterval(interval);
        setIsDelayComplete(true);
      }
    }, 100);

    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, [reverse_auction_data?.reverse_auction_status_id]);

  useEffect(() => {
    if (isActiveQuotationFetching) {
      let reverseAuctionStatus =
        reverse_auction_data?.reverse_auction_status_id;

      if (reverseAuctionStatus === 0) {
        pollReverseAuctionStatus();
      } else {
        const timer = setTimeout(() => {
          setIsDelayComplete(true);
        }, 1000);

        // Cleanup the timeout on unmount
        return () => clearTimeout(timer);
      }
    }
  }, [
    isActiveQuotationFetching,
    reverse_auction_data?.reverse_auction_status_id,
    pollReverseAuctionStatus,
  ]);

  // cja: timer code block (start) ==================================================================================================================================>
  useEffect(() => {
    if (
      reverse_auction_data?.auction_time_remaining !== undefined &&
      reverse_auction_data?.reverse_auction_status_id !== undefined &&
      isRemainingTimeLoading &&
      isDelayComplete
    ) {
      setisActiveQuotationFetching(false);
      /*
        Active Quotation Status ID Legend:
          1 - In Progress
          2 - Waiting
          3 - Expired
          4 - Completed
      */
      let reverseAuctionStatus =
        reverse_auction_data?.reverse_auction_status_id;

      let secondsRemaining = reverse_auction_data?.auction_time_remaining;

      if (reverseAuctionStatus === 1) {
        // view state definition for the legend
        setAuctionStatus(reverseAuctionStatus);
        // 8:00 - 11:00 (remaining - 10860) - 10860
        // 11:01 - 12:00 (remaining - 7200) - 7260
        // 12:01 - 1:00 (remaining - 3600) - 3660
        setRemainingTimeInSeconds(secondsRemaining);
      } else if (reverseAuctionStatus === 3) {
        // view state definition for the legend
        setRemainingTimeInSeconds(0);
        setAuctionStatus(reverseAuctionStatus);
      } else if (reverseAuctionStatus === 2) {
        getReverseAuctionOpeningTime(_token)
          .then((response: any) => {
            setRemainingTimeInSeconds(
              Number(response.data.items.opening_auction_time)
            );
          })
          .catch((err) => console.log(err));

        setAuctionStatus(reverseAuctionStatus);
      }

      setIsRemainingTimeLoading(false);
    }
  }, [
    reverse_auction_data?.auction_time_remaining,
    reverse_auction_data?.reverse_auction_status_id,
    isRemainingTimeLoading,
    _token,
    isDelayComplete,
  ]);

  // cja: custom event listener for indentifying if the current tab is the reverse auction page (Active Quotation Remaining Time)
  const handleVisibilityChange = () => {
    setIsRemainingTimeLoading(true);
    if (!document.hidden) {
      // if user is from another tab and return to the auction page, it will fetch the updated time remaining
      const _token = getTokenFromLocalStorage() ?? "";
      getUpdatedRemainingReverseAuctionTime(_token).then((response: any) => {
        if (response.remaining_auction_time !== undefined) {
          setRemainingTimeInSeconds(Number(response.remaining_auction_time));
          setIsRemainingTimeLoading(false);
        }
      });
    }
  };

  // cja: this hook is executed if the user is focusing to the revese auction browser
  useEffect(() => {
    // if auction status is On-going
    // Add a listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);
    if (!isTimerPaused) {
      const intervalId = setInterval(() => {
        setRemainingTimeInSeconds((prevRemainingTimeInSeconds) => {
          if (prevRemainingTimeInSeconds > 0) {
            return prevRemainingTimeInSeconds - 1;
          } else if (prevRemainingTimeInSeconds === 0) {
            // view state definition for the legend
            setAuctionStatus(3);
            return 0;
          } else {
            return 0;
          }
        });
      }, 1000);

      return () => {
        clearInterval(intervalId);
        // Remove the visibility change listener
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    } else {
      const _token = getTokenFromLocalStorage() ?? "";
      getUpdatedRemainingReverseAuctionTime(_token).then((response: any) => {
        if (response.remaining_auction_time !== undefined) {
          setRemainingTimeInSeconds(Number(response.remaining_auction_time));
          setIsRemainingTimeLoading(false);
        }
      });
    }
  }, [isTimerPaused]);

  // cja: this hook will be executed once the remainging time is equal to 0
  useEffect(() => {
    if (auctionStatus === 3 && isRefreshingExpired) {
      refreshHandler();
      setIsRefreshingExpired(false);
    }
  }, [isRefreshingExpired, auctionStatus, refreshHandler]);

  // cja: timer code block (end) ==================================================================================================================================>

  // cja: get the steps saved in session, to be used in updating the steps component
  const [stepsFromSession, setStepsFromSession] = useState<StepType[]>([]);
  useEffect(() => {
    const str_steps = sessionStorage.getItem("steps");
    str_steps != null && setStepsFromSession(JSON.parse(str_steps));
  }, []);

  // cja: update the step component
  const [updatedSteps, setUpdatedSteps] = useState<StepType[]>([]);
  useEffect(() => {
    if (stepsFromSession.length !== 0) {
      manageSteps(
        { name: "Quotation", href: "/quotation", status: "current" },
        stepsFromSession
      ).then((updated_steps: any) => {
        setUpdatedSteps(updated_steps);
      });
    }
  }, [stepsFromSession]);

  // this meta data component will be extracted from the dummy quotation details
  const MetaDataComponent: MetaType[] = [
    {
      text: reverse_auction_data?.shipping_method,
      icon: (
        <TruckIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
      ),
    },
    {
      text: formatToReadableDate(reverse_auction_data?.delivery_date),
      icon: (
        <CalendarIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
      ),
    },
    {
      text: reverse_auction_data?.delivery_location,
      icon: (
        <MapPinIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
      ),
    },
  ];

  const cardActionClickHandler = (
    account_type: string,
    action_type: string
  ) => {
    if (account_type === "Supplier" && action_type === "others") {
      viewOtherSuppliers();
    }
    if (account_type === "Logistic") {
      viewOtherLogistics();
    }
  };

  const viewOtherSuppliers = () => setOtherSuppliersModalIsOpen(true);

  const viewOtherLogistics = () => setOtherLogisticsModalIsOpen(true);

  const isItemEditable = () => {
    if (props.user_data.role === 1) {
      return false;
    } else if (props.user_data.role === 2) {
      return (
        props.user_data.role === 2 &&
        supplier_data.supplier_details.length !== 0 &&
        props.user_data._id === supplier_data.cheapestSupplier?.supplier_id
      );
    } else {
      return false;
    }
  };

  // extracting of current supplier's account offer
  useEffect(() => {
    if (
      supplier_data &&
      supplier_data.supplier_details &&
      supplier_data.supplier_details.length > 0
    ) {
      const matchingSupplier = supplier_data.supplier_details.find(
        (supplier: any) =>
          supplier?.supplier_id === props.user_data._id &&
          supplier.id === offerDetails.offerID
      );

      if (matchingSupplier) {
        setSupplierOffer(matchingSupplier.offers);
      }
    }
  }, [
    supplier_data,
    props.user_data._id,
    props.user_data.role,
    offerDetails.offerID,
  ]);

  const handleSelectSupplier = () => {
    setOtherSuppliersModalIsOpen(false);
    // Check if a valid index is provided
    if (selectedSupplierIndex !== -1) {
      // Access the selected supplier based on the index
      const selectedSupplier =
        supplier_data.supplier_details[selectedSupplierIndex];

      const updatedSupplierData = supplier_data?.supplier_details?.map(
        (supplier: any) => ({
          ...supplier,
          is_selected: supplier.id === selectedSupplier.id, // Set `is_selected` to true for the selected supplier
        })
      );

      const accreditedLogistics = logistic_data?.logistic_details?.filter(
        (logistic: any) =>
          logistic.accreditated_by[0] === selectedSupplier.supplier_id
      );

      // Dispatch updated supplier data and other reverse auction details to Redux
      dispatch(
        setReverseAuctionDetails({
          reverse_auction_data: reverse_auction_data || {}, // Ensure reverse_auction_data is passed
          buyer_data: buyer_data || {}, // Ensure buyer_data is passed
          supplier_data: {
            ...supplier_data,
            supplier_details: updatedSupplierData, // Updated supplier details
          },
          logistic_data:
            // logistic_data || null,
            {
              ...logistic_data,
              selected_logistic: accreditedLogistics || {}, // Update with the selected logistic
            }, // Ensure logistic_data is passed
        })
      );

      // Prepare updated card data based on the updatedSupplierData
      const updatedCardData = [
        {
          name: buyer_data?.buyer_details?.company_name || "No buyer available",
          icon: UserIcon,
          role: "Buyer",
          imageUrl:
            buyer_data?.buyer_details?.company_logo || "default-image-url",
          count: buyer_data?.buyer_details ? 1 : 0,
        },
        {
          name:
            selectedSupplier?.supplier_details?.company_name ||
            "No selected supplier available",
          icon: TruckIcon,
          role: "Supplier",
          imageUrl:
            selectedSupplier?.supplier_details?.company_logo ||
            "default-image-url",
          count: supplier_data?.supplier_details?.length || 0,
        },
        // Spread in the logistic card data dynamically
        ...(accreditedLogistics?.length > 0
          ? [
              {
                name:
                  accreditedLogistics[0]?.logistic_details?.company_name ||
                  "Logistic available",
                icon: TruckIcon,
                role: "Logistic",
                imageUrl:
                  accreditedLogistics[0]?.logistic_details?.company_logo ||
                  "default-image-url",
                count: accreditedLogistics?.length,
              },
            ]
          : [
              {
                name: "No logistic available",
                icon: TruckIcon,
                role: "Logistic",
                imageUrl: "default-image-url",
                count: 0,
              },
            ]),
      ];

      // Call the updateCardData function to update the card data
      if (updateCardData) {
        updateCardData(updatedCardData);
      }

      // Prepare data for API request
      const requestData = {
        reverse_auction_id: reverse_auction_data.reverse_auction_id,
        selected_offer: selectedSupplier.id,
        selected_supplier: selectedSupplier.supplier_id,
      };
      // Make API request to save the selected supplier
      postSelectedSupplierAPI(_token, requestData)
        .then((response: any) => {
          // Check response status and handle accordingly
          if (response.statusCode === 201) {
            // Close the modal or perform any other actions on successful response
            // refreshHandler(); // <-- Trigger the refresh here
            // Re-fetch the reverse auction details to ensure the updated state is fetched from the API
          } else {
            // Handle non-success responses if needed
            console.error("Failed to select supplier:", response);
          }
        })
        .catch((error: any) => {
          // Log errors from API request
          console.error("Error during API request:", error);
        });
    } else {
      // Handle case where the provided index is invalid
      console.warn("Invalid supplier index provided.");
    }
  };

  const handleSelectLogistic = () => {
    // Logic to handle the selection and display of the selected supplier in the main section
    if (selectedLogisticIndex !== -1) {
      // const selectedLogistic =
      //   props.reverse_auction_details.suppliers[selectedLogisticIndex];
      // Perform actions with the selectedLogistic data (e.g., store in state, display in main section, etc.)
      // Update state or perform actions to display the selected supplier in the main section
    }
  };

  const openPaymentSlideOverHandler = () => {
    setIsFetchingPaymentDetails(false);
    setSlideOver(true);
  };

  useEffect(() => {
    if (slideOver) {
      if (Array.isArray(buyer_data.commodities)) {
        const total = buyer_data.commodities.reduce((acc: any, buyer: any) => {
          const price = supplier_data.cheapestSupplier.offers[0].price;
          const volume = buyer.volume;
          return acc + volume * price;
        }, 0);

        const pf = buyer_data.commodities.reduce((acc: any, buyer: any) => {
          const volume = buyer.volume;
          const price = supplier_data.cheapestSupplier.offers[0].price;
          const logisticRate = logistic_data?.cheapestLogistic?.rate || 0;
          const pf_percentage = (volume * price + volume * logisticRate) * 1.5 / 100;
          return acc + pf_percentage;
        }, 0);

        const deliveryFee = buyer_data.commodities.reduce(
          (acc: any, buyer: any) => {
            const logisticRate = logistic_data?.cheapestLogistic?.rate || 0;
            const volume = buyer.volume;
            return acc + volume * logisticRate;
          },
          0
        );

        const roundedTotal = parseFloat(total.toFixed(2));
        const roundedPf = parseFloat(pf.toFixed(2));
        const roundedLogisticRate = parseFloat(deliveryFee.toFixed(2));


        const overAllTransaction = parseFloat(
          (roundedLogisticRate + roundedTotal + roundedPf).toFixed(2)
        );

        setTotalTransactionCost(overAllTransaction);
        setDeliveryFee(deliveryFee);
        setProcessingFee(roundedPf);
        setSubTotal(total);
      }
    }
  }, [
    buyer_data,
    supplier_data,
    slideOver,
    logistic_data?.cheapestLogistic?.rate,
  ]);
  // cja: calculate subtotal and transaction cost if changes occured
  // useEffect(() => {
  // 	if (
  // 		toPayCommodities.length !== 0 &&
  // 		otherCommodityFees.delivery_fee !== 0
  // 	) {
  // 		let updatedSubTotal = 0;
  // 		toPayCommodities.length !== 0 &&
  // 			toPayCommodities.forEach(
  // 				(commodity: {
  // 					commodity_name: string;
  // 					requested_volume: string;
  // 					total: number;
  // 				}) => {
  // 					updatedSubTotal = updatedSubTotal + commodity.total;
  // 				}
  // 			);

  // 		let updatedTotalTransactionCost =
  // 			updatedSubTotal +
  // 			otherCommodityFees.delivery_fee +
  // 			otherCommodityFees.less_2307_fee +
  // 			otherCommodityFees.bank_service_charge_fee +
  // 			otherCommodityFees.value_added_tax_fee;

  // 		setSubTotal(updatedSubTotal);
  // 		// setTotalTransactionCost(updatedTotalTransactionCost);
  // 		setToPayCommodities([]);
  // 	}
  // }, [
  // 	toPayCommodities,
  // 	setOtherCommodityFees,
  // 	otherCommodityFees.delivery_fee,
  // 	otherCommodityFees.less_2307_fee,
  // 	otherCommodityFees.bank_service_charge_fee,
  // 	otherCommodityFees.value_added_tax_fee,
  // ]);
  // If no supplier is selected, fallback to the cheapest supplier

  // const fallbackSupplier = !selectedSupplier
  // 	? supplier_data.cheapestSupplier
  // 	: null;

  const [lastUpdatedOffers, setLastUpdatedOffers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchUpdatedPrices = useCallback(
    async (offers: any) => {
      setLoading(true); // Start loading
      const updatedOffersPromises = offers.map(async (offer: any) => {
        try {
          const response: any = await getUpdatedPricesAPI(
            _token,
            offer.offer_variation_id,
            String(props.reverse_auction_id)
          );

          if (
            response.statusCode === 200 &&
            response.data &&
            response.data.items.length > 0
          ) {
            const updatedPriceData = response.data.items[0];
            return { ...offer, price: updatedPriceData.updated_price };
          }
          return offer;
        } catch (error) {
          console.error(
            `Error updating price for offer variation ID ${offer.offer_variation_id}:`,
            error
          );
          return offer; // Return original offer on error
        }
      });

      const updatedOffers = await Promise.all(updatedOffersPromises);

      // Set both `supplierOffer` and `lastUpdatedOffers` to hold updated offers
      setSupplierOffer(updatedOffers);
      setLastUpdatedOffers(updatedOffers); // Store the latest successful update as fallback
      setLoading(false); // End loading
    },
    [_token, props.reverse_auction_id]
  );

  useEffect(() => {
    if (selectedSupplier) {
      const initialOffers = Array.isArray(selectedSupplier.offers)
        ? selectedSupplier.offers
        : [];
      setSupplierOffer(initialOffers);

      const selectedIndex = supplierDetails.findIndex(
        (supplier: any) => supplier.is_selected
      );
      setSelectedSupplierIndex(selectedIndex);

      fetchUpdatedPrices(initialOffers);
    }
  }, [selectedSupplier, supplierDetails, fetchUpdatedPrices]);

  // const handleSupplierClick = (index: number) => {
  // 	const supplier = supplierDetails[index];
  // 	setSelectedSupplierIndex(index);

  // 	if (supplier) {
  // 		// Directly set the offers for the clicked supplier
  // 		setSupplierOffer(Array.isArray(supplier.offers) ? supplier.offers : []);
  // 		// Since price updates are handled in the useEffect, no need for API call here
  // 		console.log("supplier",supplier)
  // 		console.log("supplierOffer",supplierOffer)
  // 		console.log("supplierDetails",supplierDetails)
  // 		console.log("selectedSupplierIndex",selectedSupplierIndex)
  // 	}
  // };
  const handleSupplierClick = (index: number) => {
    const supplier = supplierDetails[index];
    setSelectedSupplierIndex(index);

    if (supplier) {
      const currentOffers = Array.isArray(supplier.offers)
        ? supplier.offers
        : [];

      // If no new updates, fall back to `lastUpdatedOffers`
      if (supplierOffer.length === 0) {
        setSupplierOffer(lastUpdatedOffers);
      } else {
        // Otherwise, update with current offers and fetch potential new updates
        setSupplierOffer(currentOffers);
        fetchUpdatedPrices(currentOffers);
      }
    }
  };

  // cja: this will be displayed in seller pov only
  const renderOtherSuppliers = () => (
    <>
      <div className="flex flex-wrap justify-center my-4 mx-4">
        {/* Single card (start) */}
        <div className="flex flex-col w-full md:w-2/5">
          <nav className="flex flex-1 flex-col" aria-label="Sidebar">
            <ul>
              {supplierDetails.length > 0 &&
                supplierDetails.map((supplier: any, index: number) => {
                  // Determine if the supplier is selected
                  const isSelected = supplier?.is_selected;
                  // Only mark as cheapest if isSelected is false
                  const isCheapest =
                    supplier.supplier_id ===
                    supplier_data?.cheapestSupplier?.supplier_id;

                  // Determine the highlight condition
                  const isHighlighted =
                    isSelected ||
                    (!supplierDetails.some((s: any) => s.is_selected) &&
                      isCheapest);

                  return (
                    <li
                      key={index}
                      className={classNames(
                        isHighlighted
                          ? "bg-dealogikal-100/10 text-dealogikal-100"
                          : // : isCheapest
                            // ? "bg-dealogikal-100/10 text-dealogikal-100"
                            "text-gray-700 hover:text-dealogikal-100 hover:bg-gray-100",
                        "group flex gap-x-3 rounded-md p-6 py-5 text-base leading-6 font-normal cursor-pointer"
                      )}
                      onClick={() => handleSupplierClick(index)}
                    >
                      <span className="mt-2 font-extrabold">{`${
                        index + 1
                      }.`}</span>
                      <img
                        className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-50"
                        src={supplier.supplier_details.company_logo}
                        alt=""
                      />
                      <p className="mt-2 w-full">{`${
                        supplier.supplier_details.company_name
                      } ${
                        supplier?.supplier_id === props.user_data._id
                          ? "(You)"
                          : ""
                      }`}</p>
                    </li>
                  );
                })}
            </ul>
          </nav>
        </div>
        {/* Single card (end) */}

        {/* Single card (start) */}
        <div className="flex flex-col w-full md:w-3/5">
          <nav className="flex flex-1 flex-col" aria-label="Sidebar">
            <ul>
              <li
                className={classNames(
                  "text-gray-400 group flex gap-x-3 rounded-md px-6 py-1 text-base leading-6 font-normal cursor-pointer justify-between border-b"
                )}
              >
                <p className="mt-2 text-sm">Items</p>
                <p className="mt-2 text-sm">Price</p>
              </li>
              {supplierOffer.map((offer: any, offerIndex: number) => {
                const {
                  commodity_id,
                  commodity_name,
                  price,
                  minimum_quantity,
                  maximum_quantity,
                } = offer;

                const commodityIds = buyer_data.commodities
                  .filter(
                    (commodity: any) => commodity.volume >= minimum_quantity
                  )
                  .map((commodity: any) => commodity.commodity_id);

                const textColorClass = commodityIds.includes(commodity_id)
                  ? "text-gray-900"
                  : "text-gray-200";

                return (
                  <li
                    key={generateKey(
                      `${commodity_id}_${offerIndex}_${commodity_id}`
                    )}
                    className={classNames(
                      textColorClass,
                      "group flex gap-x-3 rounded-md px-6 py-1 text-base leading-6 font-normal cursor-pointer justify-between"
                    )}
                  >
                    <p className="mt-2">
                      {commodity_name}
                      <br />
                      <small>{`${minimum_quantity} - ${maximum_quantity}`}</small>
                    </p>
                    <br />
                    <div key={generateKey(commodity_name)}>
                      {loading ? ( // Check if loading
                        <Lottie
                          animationData={CircularLoading}
                          loop
                          autoplay
                          style={{ width: "20px", height: "20px" }} // Adjust size as needed
                        />
                      ) : (
                        <p className="mt-3 text-sm font-bold">
                          {`${pesoSign()} ${price}`}
                        </p>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
        {/* Single card (end) */}
      </div>
      {props.user_data.role === 1 && (
        <div className="p-2 mx-2">
          <button
            type="button"
            onClick={handleSelectSupplier}
            className="w-full rounded-md bg-dealogikal-100 px-3.5 py-4 text-sm font-semibold text-white shadow-sm sm:hover:bg-dealogikal-50 focus-visible:outline-none sm:w-full"
          >
            Select
          </button>
        </div>
      )}
    </>
  );

  const renderOtherLogistics = () => (
    <>
      <div className="flex flex-wrap justify-center my-4 mx-4">
        {/* single card (start) */}
        <div className="flex flex-col w-full md:w-2/5">
          <nav className="flex flex-1 flex-col" aria-label="Sidebar">
            <ul>
              {logistic_data &&
                logistic_data.logistic_details &&
                logistic_data.logistic_details.length > 0 &&
                logistic_data.logistic_details.map(
                  (logistic: any, index: number) => (
                    <li
                      key={index}
                      className={classNames(
                        index === selectedSupplierIndex
                          ? "bg-gray-100 text-dealogikal-100"
                          : "text-gray-700 hover:text-dealogikal-100 hover:bg-gray-100",
                        "group flex gap-x-3 rounded-md p-6 py-5 text-base leading-6 font-normal cursor-pointer"
                      )}
                      onClick={() => setSelectedLogisticIndex(index)}
                    >
                      <span className="mt-2 font-extrabold">{`${
                        index + 1
                      }.`}</span>
                      <img
                        className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-50"
                        src={logistic.logistic_details.company_logo}
                        alt=""
                      />
                      <p className="mt-2 w-full">{`${
                        logistic.logistic_details.company_name
                      } ${
                        logistic.logistic_id === props.user_data._id
                          ? "(You)"
                          : ""
                      }`}</p>
                    </li>
                  )
                )}
            </ul>
          </nav>
        </div>
        {/* single card (end) */}

        {/* single card (start) */}
        <div className="flex flex-col w-full md:w-3/5">
          <nav className="flex flex-1 flex-col" aria-label="Sidebar">
            <ul>
              <li
                className={classNames(
                  "text-gray-400 group flex gap-x-3 rounded-md px-6 py-1 text-base leading-6 font-normal cursor-pointer justify-between border-b"
                )}
              >
                <p className="mt-2 text-sm">Vehicle</p>
                <p className="mt-2 text-sm">Rate</p>
              </li>
              {logistic_data &&
                logistic_data.logistic_details &&
                logistic_data.logistic_details.length > 0 &&
                logistic_data.logistic_details.map(
                  (logistic: any, index: number) => {
                    return (
                      <Fragment key={generateKey(Math.random().toString())}>
                        {index === selectedLogisticIndex ? (
                          <>
                            <li
                              key={generateKey(
                                Math.random() + logistic.offer_id
                              )}
                              className={classNames(
                                "text-gray-900 group flex gap-x-3 rounded-md px-6 py-1 text-base leading-6 font-normal cursor-pointer justify-between"
                              )}
                            >
                              <p className="mt-2">
                                {logistic.vehicle_name} ({logistic.plate_number}
                                )
                                <br />
                                <small>
                                  Capacity:{" "}
                                  <strong>{`${logistic.volume}`}</strong>
                                </small>
                              </p>
                              <p className="mt-2 text-sm font-bold">
                                {logistic.rate
                                  ? `${pesoSign()} ${logistic.rate}`
                                  : `No available rate`}
                              </p>
                            </li>
                            <li
                              className={classNames(
                                "text-gray-900 group flex gap-x-3 rounded-md px-6 py-1 text-base leading-6 font-normal cursor-pointer justify-between"
                              )}
                            >
                              {/* <p className="text-sm">
                                  {logistic.status === null ? (
                                          <span className="text-red-600">You are not accredited with the current supplier. Please apply your vehicle to the accreditation</span> // or any placeholder or alternative content
                                        ) : (
                                          <span>
                                            Accreditation Status: <strong className={logistic.status}>{logistic.status}</strong>
                                          </span>
                                        )}
                                  </p> */}
                            </li>
                          </>
                        ) : null}
                      </Fragment>
                    );
                  }
                )}
            </ul>
          </nav>
        </div>
        {/* single card (end) */}
      </div>
      {props.user_data.role === 1 && (
        <div className="p-2 mx-2">
          <button
            type="button"
            onClick={handleSelectLogistic}
            className="w-full rounded-md bg-dealogikal-100 px-3.5 py-4 text-sm font-semibold text-white shadow-sm sm:hover:bg-dealogikal-50 focus-visible:outline-none sm:w-full"
          >
            Select
          </button>
        </div>
      )}
    </>
  );

  // cja: calendar component states (start)
  const [deliveryDateModalOpen, setDeliveryDateModalOpen] = useState(false);
  const [deliveryDaysIsError, setDeliveryDaysIsError] =
    useState<boolean>(false);
  const [chosenDaysDelivery, setChosenDaysDelivery] = useState<{
    id: number;
    name: string;
  }>({ id: 0, name: "" });
  const [noSelectedDateError, setNoSelectedDateError] =
    useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<{
    month: number;
    day: number;
    year: number;
  }>({ month: 0, day: 0, year: 0 });
  const [datesEnabled, setDatesEnabled] = useState<EnabledDatesModel[]>([]);
  const [isProceeding, setIsProceeding] = useState<boolean>(false);

  const daysOfDelivery = [
    { id: 2, name: "2 DAYS" },
    { id: 3, name: "3 DAYS" },
    { id: 4, name: "4 DAYS" },
    { id: 5, name: "5 DAYS" },
  ];

  const [dateOfRevive, setDateOfRevive] = useState<number>(0);

  // cja: this useEffect hook will identify the dates that needs to be enable and pass to calendar component
  useEffect(() => {
    if (chosenDaysDelivery.name !== "") {
      let chosenDays: number = parseInt(
        chosenDaysDelivery.name.replace(" DAYS", "")
      );

      let newDatesEnabled: EnabledDatesModel[] = [];
      // default dealogikal operation lead time
      let operationsLeadTime = getOperationLeadTime();

      const currentDate = new Date();
      const dayOfMonth = currentDate.getDate();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      // call the function in identifying the total number of days this current month
      // since getMonth() function returns zero-based month, we need to add 1 in currentMonth state
      const totalNumberOfDaysInMonth = getTotalNumberOfDaysInMonth(
        currentMonth + 1,
        currentYear
      );
      // initialize the expected transaction duration starting from reviving the auction
      // then add days lead time for the weekdays below
      // identifying day of the week for tomorrow
      //const dateTomorrow = dayOfMonth + 1;
      //const newDate = new Date(currentYear, currentMonth, dateTomorrow);
      /*
        1 - Monday
        2 - Tuesday
        3 - Wednesday
        4 - Thursday
        5 - Friday
        6 - Saturday
        7 - Sunday
      */

      // calculate the revive start date
      let revivalStartDate = dayOfMonth;
      if (tomorrowDayOfWeek === 6) {
        revivalStartDate = revivalStartDate + 2;
        setDateOfRevive(revivalStartDate);
      } else if (tomorrowDayOfWeek === 7) {
        revivalStartDate = revivalStartDate + 1;
        setDateOfRevive(revivalStartDate);
      } else {
        revivalStartDate = dayOfMonth + 1;
        setDateOfRevive(revivalStartDate);
      }

      // add the operations lead time
      const startingDeliveryDate = revivalStartDate + operationsLeadTime;

      for (let i = 1; i <= chosenDays; i++) {
        let enabledDate: EnabledDatesModel;
        let currentDeliveryDate = startingDeliveryDate + i - 1;

        if (currentDeliveryDate > totalNumberOfDaysInMonth) {
          // Handle dates in the next month
          let nextMonth = (currentMonth + 1) % 12;
          let nextYear = currentYear + (nextMonth === 0 ? 1 : 0);
          let nextMonthDate = currentDeliveryDate - totalNumberOfDaysInMonth;

          enabledDate = {
            month: nextMonth + 1,
            date: nextMonthDate,
            year: nextYear,
          };
        } else {
          enabledDate = {
            month: currentMonth + 1,
            date: currentDeliveryDate,
            year: currentYear,
          };
        }

        newDatesEnabled = [...newDatesEnabled, enabledDate];
      }
      setDatesEnabled(newDatesEnabled);
    }
  }, [chosenDaysDelivery, tomorrowDayOfWeek]);

  // cja: delivery date modal blocks (start)
  const dateOnClickHandler = (
    month: number,
    day: number,
    year: number,
    enabled: boolean
  ) => {
    // can only select on enabled dates
    if (enabled) {
      // Check if the clicked date is in the enabled dates
      const isDateEnabled = datesEnabled.some(
        (date) =>
          date.month === month && date.date === day && date.year === year
      );

      if (isDateEnabled) {
        setSelectedDate({
          month,
          day,
          year,
        });
      }
    }
  };

  // "OK" button click handler of calendar modal in reviving auction
  const reviveClickHandler = () => {
    setIsProceeding(true);
    // validation 1: validate if already selected delivery days
    if (chosenDaysDelivery.name === "" && dateOfRevive === 0) {
      setIsProceeding(false);
      setDeliveryDaysIsError(true);
    } else {
      setDeliveryDaysIsError(false);
      // validation 2: check if the user already selected a specific date of delivery
      if (
        selectedDate.month === 0 &&
        selectedDate.day === 0 &&
        selectedDate.year === 0
      ) {
        setIsProceeding(false);
        setNoSelectedDateError(true);
      } else {
        setNoSelectedDateError(false);
        let rescheduleData = {
          reverse_auction_id: props.reverse_auction_id,
          //delivery_date: `03 - 26 - 2024`,
          delivery_date: `${selectedDate.month} - ${selectedDate.day} - ${selectedDate.year}`,
        };

        rescheduleExpiredReverseAuction(_token, rescheduleData)
          .then((response: any) => {
            setIsProceeding(false);
            if (response.statusCode === 200) {
              setDeliveryDateModalOpen(false);

              setToastIcon("success");
              setToastMessage(response.data.message);
              setShowToast(true);
              setTimeout(() => {
                setShowToast(false);
              }, 3000);
              setTimeout(() => {
                navigate("/b/reverse_auction_list");
              }, 3500);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };
  // cja: calendar component states (end)

  // main loading state for fetching the app_reference(terms and agreement text) api call
  const [fetchingTermsAgreement, setFetchingTermsAgreement] =
    useState<boolean>(false);

  // terms and agreement modal state
  const [isTermsAgreementModalOpen, setIsTermsAgreementModalOpen] =
    useState<boolean>(false);
  // this state will prevent redundant api calls
  const [isTermsAgreementTextFetching, setIsTermsAgreementTextFetching] =
    useState<boolean>(true);
  // app reference (terms and agreement) content/text
  const [termsAgreementText, setTermsAgreementText] = useState<string>("");

  // cja: buyer book order click handler
  const bookNowClickHandler = () => {
    setFetchingTermsAgreement(true);
    if (isTermsAgreementTextFetching && termsAgreementText === "") {
      getBookWarningReferenceAPI(_token, 1)
        .then((response: any) => {
          if (response.statusCode === 200) {
            const { description } = response.data.items[0];
            setTermsAgreementText(description);
            setIsTermsAgreementTextFetching(false);
            setFetchingTermsAgreement(false);
            setIsTermsAgreementModalOpen(true);

          }
        })
        .catch((err) => console.log(err));
    } else if (!isTermsAgreementTextFetching && termsAgreementText !== "") {
      setIsTermsAgreementModalOpen(true);
      setFetchingTermsAgreement(false);
    }
    if (socket && socket.readyState === WebSocket.OPEN) {
			const socketPayload = {
				title: "Pre-order Buyer",
				message: "-------",//`${props.user_data.company} selected ${logistic_data.logistic_details.company_name} logistics.`,
				user_id: 6,
				notification_type_id: 1,
				source_id: 1,
			};
			sendWebSocketMessage("sendNotification", socketPayload);
		}

    setTriggerEffect((prev) => !prev);
  };

  // cja: supplier confirm order click handler
  const confirmOrderClickHandler = useCallback(() => {
    setFetchingTermsAgreement(true);

    // Assuming orderList has at least one order in its array
    const order = orderList.orders.find(
      (order) =>
        String(order.reverse_auction_id) === String(props.reverse_auction_id)
    );

    const emailHtmlContent = renderToString(
      <BookingConfirmationEmailSenderComponent
        customerName={order?.customer_name || ""}
        orderNumber={String(order?.reference_number || "")}
        productType={order?.requested_commodity.split(" (")[0] || ""}
        quantityOrdered={
          order?.requested_commodity.split(" (")[1]?.replace(")", "") || ""
        }
        estimatedDeliveryDate={order?.requested_date || ""}
      />
    );

    const data = {
      to: "rlm@dealogikal.com",
      from: "rlm@dealogikal.com",
      subject: "Dealogikal Test Email Sender",
      htmlContent: emailHtmlContent,
    };

    if (isTermsAgreementTextFetching && termsAgreementText === "") {
      getBookWarningReferenceAPI(_token, 2)
        .then((response: any) => {
          if (response.statusCode === 200) {
            if (props.user_data.role === 6) {
              postBookingEmailSender(_token, data); // Email sent only for logistics role
            }
            const { description } = response.data.items[0];
            setTermsAgreementText(description);
            setIsTermsAgreementTextFetching(false);
            setFetchingTermsAgreement(false);
            setIsTermsAgreementModalOpen(true);
          }
        })
        .catch((err) => console.log(err));
    } else if (!isTermsAgreementTextFetching && termsAgreementText !== "") {
      setIsTermsAgreementModalOpen(true);
      setFetchingTermsAgreement(false);
    }
  }, [
    _token,
    isTermsAgreementTextFetching,
    termsAgreementText,
    orderList,
    props.reverse_auction_id,
    props.user_data.role
  ]);

  // cja: edit commodity price blocks (start) ==============================================================================================>
  // this is the function handler that will handle the edit price. The reason why this handler is outside the StaticTableComponent is that,
  // by the time this hadnler is being triggered, it will set a local state flag allowing the timer to be pause for the meantime.
  const [enabledPriceEditor, setEnabledPriceEditor] =
    useState<EnabledPriceEditorType>({
      offer_id: 0,
      offer_variation_id: 0,
      commodity_name: "",
      current_price: "",
      updated_price: "",
    });
  const [isPriceUpdateModalOpen, setIsPriceUpdateModalOpen] =
    useState<boolean>(false);
  const [isUpdatingPrice, setIsUpdatingPrice] = useState<boolean>(false);

  // handler that will handle the price update click event from StaticTableComponent
  const editPriceClickHandler = (
    enabledPriceEditor: EnabledPriceEditorType
  ) => {
    // set the timer pause and display the modal
    setIsTimerPaused(true);
    setEnabledPriceEditor(enabledPriceEditor);
    setIsPriceUpdateModalOpen(true);
  };

  // this handler will be triggered upon price update modal on close
  const priceUpdateModalOnCloseHandler = () => {
    setIsPriceUpdateModalOpen(false);
    setIsTimerPaused(false);
    setEnabledPriceEditor({
      offer_id: 0,
      offer_variation_id: 0,
      commodity_name: "",
      current_price: "",
      updated_price: "",
    });

    const _token = getTokenFromLocalStorage() ?? "";
    getUpdatedRemainingReverseAuctionTime(_token).then((response: any) => {
      if (response.remaining_auction_time !== undefined) {
        setRemainingTimeInSeconds(Number(response.remaining_auction_time));
        setIsRemainingTimeLoading(false);
      }
    });
  };

  // cja: handler upon typing on the updated price textbox
  const updatedPriceOnChangeHandler = (e: any) => {
    const accepted_characters = /^[0-9,.\b]+$/;

    if (e.target.value === "" || accepted_characters.test(e.target.value)) {
      if (
        countDecimalPoints(e.target.value) < 2 &&
        e.target.value.charAt(0) !== "."
      ) {
        setEnabledPriceEditor((prev) => ({
          ...prev,
          updated_price: e.target.value,
        }));
      }
    }
  };

  // call API handler in updating the price
  const updatePrice = () => {
    setIsUpdatingPrice(true);
    let updatePriceData = {
      reverse_auction_id: props.reverse_auction_id.toString(),
      offer_variation_id: enabledPriceEditor.offer_variation_id,
      old_price: enabledPriceEditor.current_price,
      new_price: enabledPriceEditor.updated_price,
    };
    updateOfferVariationPrice(_token, updatePriceData)
      .then((response: any) => {
        if (response.statusCode === 200) {
          setIsUpdatingPrice(false);
          setIsPriceUpdateModalOpen(false);
          setIsTimerPaused(false);

          setToastIcon("success");
          setToastMessage(response.data.message);
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 2000);
          setTimeout(() => {
            props.refreshHandler();
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
  };

  // cja: edit commodity price blocks (end) ==============================================================================================>

  // cja: Booking transaction process (start) ==============================================================================================>
  /*
    Booking Status Legend:

    1 - For Confimation
    2 - ...
    3 - ...
  
  */

  // local states
  const [bookingStatus, setBookingStatus] = useState<number>(0);
  const { socket, sendWebSocketMessage, socketMessage } = useWebSocket();
  const [isCheckingBooking, setIsCheckingBooking] = useState<boolean>(true);
  const [isBooked, setIsBooked] = useState<boolean>(false); // flag state for buyer upon booking an offer
  const [beenBooked, setBeenBooked] = useState<boolean>(false); // flag state if supplier's offer booked
  const [vehicleBooked, setVehicleBooked] = useState<boolean>(false); // flag state if logistics' vehicle booked
  /* cja: check reverse auction id if already booked an offer */
  useEffect(() => {
    if (isCheckingBooking && isDelayComplete) {
      const reverse_auction_data =
        props.reverse_auction_details.reverse_auction_data;

      if (reverse_auction_data && props.user_data.role) {
        checkBookingsAPI(
          _token,
          props.user_data.role,
          reverse_auction_data.reverse_auction_id
        )
          .then((res: any) => {
            if (res.statusCode === 200) {
              const { items } = res.data;

              dispatch(setOfferBookingId(items[0]?.offer_booking_id));
              setIsCheckingBooking(false);
              if (items.length === 1) {
                setBookingStatus(items[0].order_status_id);
                if (props.user_data.role === 1) {
                  //alert("Do more in buyer's RA page");
                } else if (
                  props.user_data.role === 2 &&
                  items[0].is_supplier_approve === 0
                ) {
                  setBeenBooked(true);
                } else if (
                  props.user_data.role === 6 &&
                  items[0].is_logistic_approve === 0
                ) {
                  setVehicleBooked(true);
                }
              }
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }, [
    dispatch,
    isCheckingBooking,
    _token,
    props.reverse_auction_details.reverse_auction_data,
    props.user_data.role,
    isDelayComplete,
    triggerEffect,
  ]);

  // jar: checking if bookingStatus is equal to 3 but if offerbookingID is empty then rerun the api
  useEffect(() => {
    if (bookingStatus === 3 && !offerBookingId) {
      checkBookingsAPI(
        _token,
        props.user_data.role,
        reverse_auction_data.reverse_auction_id
      ).then((res: any) => {
        if (res.statusCode === 200) {
          const { items } = res.data;

          dispatch(setOfferBookingId(items[0]?.offer_booking_id));
          setIsCheckingBooking(false);
          if (items.length === 1) {
            setBookingStatus(items[0].order_status_id);
            if (props.user_data.role === 1) {
              //alert("Do more in buyer's RA page");
            } else if (
              props.user_data.role === 2 &&
              items[0].is_supplier_approve === 0
            ) {
              setBeenBooked(true);
            } else if (
              props.user_data.role === 6 &&
              items[0].is_logistic_approve === 0
            ) {
              setVehicleBooked(true);
            }
          }
        }
      });
    }
  }, [
    bookingStatus,
    offerBookingId,
    reverse_auction_data.reverse_auction_id,
    _token,
    dispatch,
    props.user_data.role,
  ]);

  const [hasBooked, setHasBooked] = useState(false);

  const bookOffer = useCallback(() => {
    if (socket && socket.readyState === WebSocket.OPEN && !hasBooked) {
      const socketPayload = {
        offer_id: supplier_data.cheapestSupplier.id,
        reverse_auction_id: reverse_auction_data.reverse_auction_id,
      };

      sendWebSocketMessage("bookOffer", socketPayload);
      setHasBooked(true);

      const emailSupplierHtmlContent = renderToString(
        <BookingNowSupplierEmailSenderComponent
         supplierName={"Supplier 1"}
        />
      );
      const dataSupplierEmail = {
        to: "rlm@dealogikal.com",
        from: "rlm@dealogikal.com",
        subject: "Dealogikal Test Supplier Email Sender",
        htmlContent: emailSupplierHtmlContent,
      };
      postBookingEmailSender(_token, dataSupplierEmail);

      const emailLogisticsHtmlContent = renderToString(
        <BookingNowLogisticsEmailSenderComponent
         logisticName={"Logistics 1"}
        />
      );
      const dataLogisticsEmail = {
        to: "rlm@dealogikal.com",
        from: "rlm@dealogikal.com",
        subject: "Dealogikal Test Logistics Email Sender",
        htmlContent: emailLogisticsHtmlContent,
      };
      postBookingEmailSender(_token, dataLogisticsEmail);

      //navigate(`/b/my_orders/${reverse_auction_data.reverse_auction_id}`)
    }
  }, [
    _token,
    socket,
    reverse_auction_data,
    supplier_data,
    sendWebSocketMessage,
    hasBooked,
  ]);

  // cja: handle the result of the sendWebSocketMessage bookOffer action
  useEffect(() => {
    if (isBooked === true) {
      setBookingStatus(1);
      // close terms and agreement booking warning modal
      setIsTermsAgreementModalOpen(false);
      // show toast message informing that booking was successfull
      setToastIcon("success");
      setToastMessage("Booked successfully!");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [isBooked]);

  // cja: this hook will handle the socket message from web socket connection
  useEffect(() => {
    if (socketMessage !== null) {
      const strData: string = socketMessage.toString();
      if (strData === "Success") {
        // do something here
      } else {
        const socketData = JSON.parse(strData);
        const { payload } = socketData;

        if (payload.key === "OFFER_BOOKED") {
          // [for buyer]
          setIsBooked(true);
        } else if (payload.key === "OFFER_BOOKING_CONFIRMED") {
          // [for supplier]
          // close terms and agreement booking warning modal
          setIsTermsAgreementModalOpen(false);
          // show toast message informing that booking was successfull
          setToastIcon("success");
          setToastMessage("Confirmed booking successfully!");
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          // hide the confirmation button
          setBeenBooked(false);
        } else if (payload.key === "VEHICLE_BOOKING_CONFIRMED") {
          // [for logistic]
          // close terms and agreement booking warning modal
          setIsTermsAgreementModalOpen(false);
          // show toast message informing that booking was successfull
          setToastIcon("success");
          setToastMessage("Confirmed booking successfully!");
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          // hide the confirmation button
          setVehicleBooked(false);
          setBookingStatus(3);
        }
      }
    }
  }, [socketMessage]);

  // cja: this hook will check the socketResponseData state from redux
  useEffect(() => {
    const { key, data } = socketResponseData.payload;

    if (key === "BEEN_BOOKED") {
      // [for supplier]
      setBeenBooked(true);
      setBookingStatus(data.order_status_id);
    } else if (key === "VEHICLE_BOOKED") {
      // [for logistic]
      setVehicleBooked(true);
      setBookingStatus(data.order_status_id);
    } else if (key === "PAYMENT_READY" || key === "VEHICLE_CONFIRMED") {
      setBookingStatus(3);
    }
  }, [socketResponseData]);

  const confirmClickHandler = useCallback(() => {
    if (props.user_data.role === 2) {
      // confirm offer booking for SUPPLIER
      if (socket && socket.readyState === WebSocket.OPEN) {
        // Supplier will deliver OR Buyer will pick up
        if (
          (supplier_data.cheapestSupplier.shipping_method_id === 1 &&
            reverse_auction_data.shipping_method_id === 1) ||
          (supplier_data.cheapestSupplier.shipping_method_id === 2 &&
            reverse_auction_data.shipping_method_id === 2)
        ) {
          const socketPayload = {
            offer_booking_id: offerBookingId,
          };
          sendWebSocketMessage("confirmVehicleBook", socketPayload);
          setIsTermsAgreementModalOpen(false);
        } else {
          const socketPayload = {
            offer_booking_id: offerBookingId,
            vehicle_id: logistic_data.cheapestLogistic.id,
          };

          sendWebSocketMessage("confirmBook", socketPayload);
          setIsTermsAgreementModalOpen(false);
        }
      }
    } else if (props.user_data.role === 6) {
      // confirm offer booking for LOGISTICS
      const socketPayload = {
        offer_booking_id: offerBookingId,
      };

      sendWebSocketMessage("confirmVehicleBook", socketPayload);
      setIsTermsAgreementModalOpen(false);
    }
  }, [
    socket,
    props.user_data.role,
    logistic_data,
    reverse_auction_data,
    supplier_data,
    offerBookingId,
    sendWebSocketMessage,
  ]);

  // cja: Booking transaction process (end) ==============================================================================================>

  /**
   * Disabled book now button when no 3PL
   */
  const [disabledBooked, setDisabledBooked] = useState<boolean>(true);
  useEffect(() => {
    let disabled = true;

    if (
      (reverse_auction_data?.shipping_method_id === 1 &&
        supplier_data?.cheapestSupplier?.shipping_method_id === 1) ||
      (reverse_auction_data?.shipping_method_id === 2 &&
        supplier_data?.cheapestSupplier?.shipping_method_id === 2) ||
      accreditedLogistics.length > 0 // Updated condition to check if accreditedLogistics is not empty
    ) {
      disabled = false;
    }
    setDisabledBooked(disabled);
  }, [
    reverse_auction_data,
    supplier_data,
    logistic_data,
    accreditedLogistics.length,
  ]);


  const updatedUserData = {
    ...props.user_data, // Copy existing user data
    isUserSupplier: selectedSupplier
      ? props.user_data._id === selectedSupplier.supplier_id
      : false, // Add a boolean property
    //isSelectedSupplier:selectedSupplier?.is_selected
  };

  return (
    <>
      <ToasterComponent
        isOpen={showToast}
        label={message}
        onClose={setShowToast}
        type={toastIcon}
      />

      <MainConsoleLayoutComponent
        head_banner={
          isActiveQuotationFetching ? (
            <></>
          ) : (
            <>
              <ReverseAuctionBannerComponent
                auction_status={auctionStatus}
                remaining_time_in_seconds={remainingTimeInSeconds}
                is_remaining_time_loading={isRemainingTimeLoading}
                setDeliveryDateModalOpen={setDeliveryDateModalOpen}
              />
            </>
          )
        }
        content={
          <>
            {isActiveQuotationFetching ? (
              <>
                <div className="flex flex-col justify-center items-center mt-12">
                  <Lottie
                    className="w-52 h-52"
                    animationData={PageLoading}
                    loop={true}
                  />
                  <p>Loading please wait ...</p>
                </div>
                {/* <h1 className="text-3xl flex justify-center items-center content-center">Ga loading pa ooyyy saman paabot! .........</h1> */}
              </>
            ) : (
              <>
                {(auctionStatus === 3 || auctionStatus === 2) &&
                props.user_data.role !== 7 ? (
                  <ClosedAuctionStateComponent />
                ) : (
                  <>
                    {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                    <BreadcrumbContainerComponent
                      key={generateKey(Math.random().toString())}
                      breadcrumb={<BreadcrumbComponent />}
                      subtitle="Active Quotation"
                      steps={<StepsComponent steps={updatedSteps} />}
                    />
                    {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                    <div className="flex flex-wrap md:-mt-5 mt-0 mb-3 ml-1 justify-between">
                      {/* single card (start) */}
                      {props.isRefreshing === true ? (
                        <Skeleton
                          className="ml-2"
                          height={50}
                          width={400}
                          count={1}
                        />
                      ) : (
                        <div className="flex flex-col justify-start text-black bg-gray-100 px-8 py-3 pt-4 rounded-3xl">
                          <MetaComponent
                            key={generateKey(Math.random().toString())}
                            meta_data={MetaDataComponent}
                            placement="start"
                          />
                        </div>
                      )}

                      {/* single card (end) */}
                      {/* single card (start) */}
                      <div className="flex flex-col justify-start px-4 py-2 pt-2.5 rounded-3xl hover:text-dealogikal-200">
                        <Lottie
                          className="w-9 h-auto cursor-pointer"
                          animationData={Refreshing} // animation JSON data
                          loop={props.isRefreshing} // controls whether the animation loops
                          autoplay={props.isRefreshing} // controls whether the animation starts automatically
                          onClick={props.refreshHandler}
                        />
                      </div>
                      {/* single card (end) */}
                    </div>

                    <div className="mt-4 md:mt-6 sm:hidden">
                      <TabComponent />
                    </div>
                    <div className="">
                      {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                      <div className="p-2">
                        {/* display this on mobile while hide it on desktop */}
                        <div className="sm:hidden">
                          <DisclosureComponent
                            headerText="Supplier, Logistic and Finance details"
                            body={
                              <CardComponent
                                people={props.card_data}
                                cardActionClickHandler={cardActionClickHandler}
                                userData={props.user_data}
                                isRefreshing={props.isRefreshing}
                              />
                            }
                          />
                        </div>
                        {/* display this on desktop while hide it on mobile */}
                        <div className="hidden sm:block">
                          {/* cards for the regular auction (start) */}
                          <CardComponent
                            people={props.card_data}
                            cardActionClickHandler={cardActionClickHandler}
                            userData={props.user_data}
                            isRefreshing={props.isRefreshing}
                            bookingStatus={bookingStatus}
                          />
                          {/* cards for the regular auction (end) */}
                        </div>
                      </div>

                      {props.isRefreshing === true ? (
                        <>
                          <Skeleton
                            className="ml-2 mt-2"
                            height={40}
                            width={300}
                            count={1}
                          />
                        </>
                      ) : (
                        <>
                          {props.user_data.role === 1 &&
                            props.card_data[0]?.role === "" && (
                              <div className="ml-4 mt-6 mb-1">
                                <span className="text-sm md:text-base font-medium text-gray-400 self-center">
                                  -- No supplier offer available --
                                </span>
                              </div>
                            )}
                          {props.user_data.role === 1 &&
                            props.card_data[0]?.role !== "" && (
                              <div className="ml-4 mt-6 mb-1">
                                <span className="ml-2 text-black font-bold">
                                  Supplier's Offer
                                </span>
                              </div>
                            )}
                          {props.user_data.role === 2 && (
                            <div className="ml-4 mt-6 mb-1">
                              <span className="text-gray-400">Best Deal:</span>
                              <span className="ml-2 text-black">
                                {supplier_data.cheacpestSupplier !== 0 &&
                                supplier_data.cheapestSupplier?.supplier_id ===
                                  props.user_data._id ? (
                                  <span className="font-bold">Your Offer</span>
                                ) : (
                                  <>
                                    {supplier_data.length !== 0 ? (
                                      <span className="font-bold">
                                        {supplier_data.cheapestSupplier &&
                                          `${supplier_data.cheapestSupplier?.company_name}'s Offer`}
                                      </span>
                                    ) : (
                                      <span className="text-gray-600">
                                        No offer available
                                      </span>
                                    )}
                                  </>
                                )}
                              </span>
                            </div>
                          )}
                        </>
                      )}

                      <div className="p-2">
                        {props.isRefreshing === true ? (
                          <>
                            <Skeleton height={200} width={1035} count={1} />
                          </>
                        ) : (
                          <>
                            {supplier_data.cheapestSupplier !== undefined &&
                              props.card_data[0].role !== "" && (
                                <StaticTableComponent
                                  isItemEditable={isItemEditable()}
                                  user_data={updatedUserData} //{props.user_data}
                                  commodity_table={updatedOffers} //{commodityTableData}
                                  current_offer={updatedOffers} //{supplierOffer}
                                  current_order={buyer_data}
                                  logistic={
                                    logistic_data[0]?.logistic_details?.rate !==
                                      0 &&
                                    logistic_data[0]?.logistic_details?.rate
                                  }
                                  editPriceClickHandler={editPriceClickHandler}
                                />
                              )}
                          </>
                        )}
                      </div>
                      {props.isRefreshing === true ? (
                        <>
                          <Skeleton
                            className="ml-2"
                            height={50}
                            width={1040}
                            count={1}
                          />
                        </>
                      ) : (
                        <>
                          {/* Reverse auction main button for buyer */}
                          {props.user_data.role === 1 &&
                            props.card_data[0]?.role !== "" &&
                            auctionStatus === 1 &&
                            bookingStatus === 0 && (
                              <div className="p-2">
                                <button
                                  type="button"
                                  disabled={disabledBooked}
                                  className={`w-full rounded-md bg-dealogikal-100 px-3.5 py-4 text-sm font-semibold 
                                text-white shadow-sm focus-visible:outline focus-visible:outline-2 
                                focus-visible:outline-offset-2 focus-visible:outline-dealogikal-200 sm:w-2/3 "
                                ${
                                  disabledBooked
                                    ? "disabled:opacity-50 cursor-not-allowed"
                                    : "hover:bg-dealogikal-200 cursor-pointer"
                                }`}
                                  onClick={bookNowClickHandler}
                                >
                                  {fetchingTermsAgreement
                                    ? "Loading. Please wait..."
                                    : "Book now"}
                                </button>
                              </div>
                            )}
                          {/* if buyer already booked an offer this button will be rendered */}
                          {props.user_data.role === 1 &&
                            (bookingStatus === 1 || bookingStatus === 3) &&
                            auctionStatus === 1 && (
                              <div className="p-2">
                                <button
                                  type="button"
                                  className={`w-full rounded-md bg-dealogikal-100 px-3.5 py-4 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                                   focus-visible:outline-dealogikal-200 sm:w-2/3 ${
                                     bookingStatus === 1
                                       ? "disabled:opacity-50 cursor-not-allowed"
                                       : "hover:bg-dealogikal-200 cursor-pointer"
                                   }`}
                                  onClick={openPaymentSlideOverHandler}
                                  disabled={bookingStatus === 1 ? true : false}
                                >
                                  {`Proceed to Payment`}
                                </button>
                              </div>
                            )}
                          {/* Reverse auction main button for supplier */}
                          {props.user_data.role === 2 &&
                            auctionStatus === 1 &&
                            bookingStatus === 1 &&
                            beenBooked && (
                              <div className="p-2">
                                <ButtonWithTimerComponent
                                  time={300}
                                  confirmClickHandler={confirmOrderClickHandler}
                                  updateBookedStatus={() =>
                                    alert("Ready to update")
                                  }
                                />
                              </div>
                            )}
                          {/* Reverse auction main button for logistic */}
                          {props.user_data.role === 6 &&
                            auctionStatus === 1 &&
                            bookingStatus === 1 &&
                            vehicleBooked && (
                              <div className="p-2">
                                <ButtonWithTimerComponent
                                  time={300}
                                  confirmClickHandler={confirmOrderClickHandler}
                                  updateBookedStatus={() =>
                                    alert("Ready to update")
                                  }
                                />
                              </div>
                            )}
                          {/* If the supplier did not confirmed within 5 minutes, render this alert message component */}
                          {/* {(props.user_data.role === 2 ||
                          props.user_data.role === 6) &&
                          auctionStatus === 1 &&
                          bookingStatus === 2 && (
                            <div className="p-2 sm:w-2/3">
                              <AlertComponent
                                icon={XCircleIcon}
                                title={
                                  <span className="font-semibold">
                                    Order Confirmation Timeout
                                  </span>
                                }
                                sub_title={
                                  <span>
                                    We haven't received confirmation for this
                                    order within the allotted 5-minutes.
                                  </span>
                                }
                              />
                            </div>
                          )} */}
                        </>
                      )}
                    </div>

                    {/* <ChatBoxComp /> */}
                  </>
                )}
              </>
            )}
          </>
        }
      />

      <ModalComponent
        isOpen={otherLogisticsModalIsOpen}
        onCloseHandler={() => {
          setOtherLogisticsModalIsOpen(false);
          setSelectedLogisticIndex(0);
        }}
        header={
          <div className="bg-dealogikal-100 md:py-5 md:px-4 pt-3 pb-3 px-4 text-center">
            <span className="text-white inline md:text-base text-xs">
              Logistic Details
            </span>
          </div>
        }
        body={<>{renderOtherLogistics()}</>}
      />

      <ModalComponent
        isOpen={otherSuppliersModalIsOpen}
        onCloseHandler={() => {
          setOtherSuppliersModalIsOpen(false);
          setSelectedSupplierIndex(0);
        }}
        header={
          <div className="bg-dealogikal-100 md:py-5 md:px-4 pt-3 pb-3 px-4 text-center">
            <span className="text-white inline md:text-base text-xs">
              Supplier Details
            </span>
          </div>
        }
        body={<>{renderOtherSuppliers()}</>}
      />

      <ModalComponent
        isOpen={otherLogisticsModalIsOpen}
        onCloseHandler={() => {
          setOtherLogisticsModalIsOpen(false);
          setSelectedLogisticIndex(0);
        }}
        header={
          <div className="bg-dealogikal-100 md:py-5 md:px-4 pt-3 pb-3 px-4 text-center">
            <span className="text-white inline md:text-base text-xs">
              Logistic Details
            </span>
          </div>
        }
        body={<>{renderOtherLogistics()}</>}
      />

      <ModalComponent
        isOpen={isTermsAgreementModalOpen}
        onCloseHandler={() => {
          setIsTermsAgreementModalOpen(false);
        }}
        header={<></>}
        body={
          <>
            <div className="flex flex-wrap justify-center mt-2 mx-10 md:mx-32">
              {/* single card (start) */}
              <div className="flex flex-col py-5 w-full md:w-full items-center">
                <ExclamationCircleIcon
                  className="mr-1.5 h-10 w-10 flex-shrink-0 text-red-600"
                  aria-hidden="true"
                />
                <span className="text-lg text-red-600 font-bold mt-2">
                  Warning
                </span>
              </div>
              <div className="flex flex-col w-full py-2 md:w-full">
                <span className="text-sm text-gray-500 text-center md:text-lg">
                  {termsAgreementText}
                </span>
              </div>
              <div className="inline-flex w-full justify-center pt-10 pb-12 md:w-full">
                {/* for buyer terms and agreement modal buttons */}
                {props.user_data.role === 1 && (
                  <>
                    <ButtonComponent
                      text={`Yes, proceed`}
                      utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-2/4 md:w-3/4"
                      onClick={bookOffer}
                    />
                    <ButtonComponent
                      text={`Close`}
                      utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-1/4 md:w-2/4"
                      onClick={() => setIsTermsAgreementModalOpen(false)}
                    />
                  </>
                )}
                {/* for supplier terms and agreement modal buttons */}
                {props.user_data.role === 2 && (
                  <>
                    <ButtonComponent
                      text={`Yes`}
                      utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-2/4 md:w-3/4"
                      onClick={confirmClickHandler}
                    />
                    <ButtonComponent
                      text={`Cancel`}
                      utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-1/4 md:w-2/4"
                      onClick={() => setIsTermsAgreementModalOpen(false)}
                    />
                  </>
                )}
                {/* for logistic terms and agreement modal buttons */}
                {props.user_data.role === 6 && (
                  <>
                    <ButtonComponent
                      text={`Yes`}
                      utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-2/4 md:w-3/4"
                      onClick={confirmClickHandler}
                    />
                    <ButtonComponent
                      text={`Cancel`}
                      utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-1/4 md:w-2/4"
                      onClick={() => setIsTermsAgreementModalOpen(false)}
                    />
                  </>
                )}
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />

      <SlideOverComponent
        slideOver={slideOver}
        setSlideOver={setSlideOver}
        hasOverlay={true}
        header={
          <div className="bg-gray-200 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <p className="text-base font-bold text-black">Checkout</p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  type="button"
                  className="relative text-gray-600 hover:text-gray-500"
                  onClick={() => setSlideOver(false)}
                >
                  <span className="absolute -inset-2.5" />
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        }
        bodyContent={
          <>
            <div className="flex flex-col md:mx-16 w-auto mx-10 items-center justify-center mt-2">
              {/* single card (start) */}
              <div className="inline-flex w-full md:w-full justify-between mx-5">
                <h2 className="text-gray-600 text-sm">Checkout</h2>
                <h2 className="text-dealogikal-100 text-sm">
                  Prices are VAT excluded
                </h2>
              </div>

              <div className="px-4 sm:px-6 lg:px-8 w-full mt-5">
                <div className="flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-7">
                    <div className="inline-block min-w-full py-2 align-middle pt-3 px-1">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                        <div className="relative">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-xs text-xxs md:text-xs font-semibold text-gray-900 sm:pl-6"
                                >
                                  Items
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 pr-5 text-right text-xs text-xxs md:text-xs font-semibold text-gray-900"
                                >
                                  Price
                                </th>
                              </tr>
                            </thead>
                          </table>
                          <div className="max-h-40 overflow-y-auto">
                            <table className="min-w-full divide-y divide-gray-300">
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {Array.isArray(buyer_data.commodities) &&
                                  buyer_data.commodities.map(
                                    (buyer: any, index: number) => {
                                      return (
                                        <tr
                                          key={generateKey(buyer.commodity_id)}
                                        >
                                          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm">
                                            <div className="flex items-center">
                                              <div className="ml-4">
                                                <div className="text-xs font-bold text-gray-700">
                                                  {buyer.commodity_name}
                                                </div>

                                                <div
                                                  className="mt-1 text-gray-500"
                                                  key={index}
                                                >
                                                  <div className="inline-block">
                                                    {
                                                      <Square3Stack3DIcon className="h-3 w-3" />
                                                    }
                                                  </div>
                                                  <div className="inline-block ml-2 text-xs">
                                                    {buyer.volume}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            className="whitespace-nowrap text-sm md:text-base font-bold text-gray-700"
                                            key={index}
                                          >
                                            <p className="inline-block mr-5 float-right">
                                              &#8369;{" "}
                                              <span className="text-gray-700 text-sm">
                                                {supplier_data.cheapestSupplier
                                                  ?.offers[0]?.price || "NA"}
                                              </span>
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative flex flex-grow-1 flex-col justify-center items-center w-full text-sm">
                <div className="inline-flex w-full md:w-full justify-between border border-dashed mx-5 mt-8 mb-5"></div>

                <div className="inline-flex w-full md:w-full justify-between mx-6 text-sm">
                  <h2>SUBTOTAL</h2>
                  <h2 className="text-gray-500">{`${formatNumberWithComma(
                    subTotal
                  )} PHP`}</h2>
                </div>

                <div className="inline-flex w-full md:w-full justify-between border-b mx-5 mt-4"></div>
                <div className="inline-flex w-full md:w-full justify-between mx-6 mt-5 text-sm">
                  <h2>DELIVERY</h2>
                  <h2 className="text-gray-500">
                    {`${formatNumberWithComma(deliveryFee)} PHP`}
                  </h2>
                </div>
              </div>

              <div className="inline-flex w-full md:w-full justify-between border-b mx-5 mt-4"></div>
              <div className="inline-flex w-full md:w-full justify-between mx-6 mt-7 ">
                <h2>PROCESSING Fee(1.5%)</h2>
                <h2 className="text-gray-500">{`${(formatNumberWithComma(
                  processingFee)
                )} PHP`}</h2>
              </div>
              {/* single card (end) */}
              <div className="inline-flex w-full md:w-full justify-between border-b mx-5 mt-4"></div>
              {/* divider */}
              {/* single card (start) */}
              <div className="flex flex-col w-full md:w-auto border-l-8 border-dealogikal-50 mx-6 mt-12 px-3">
                <h2 className="text-md text-gray-400">transaction cost</h2>
                <h2 className="text-gray-600 text-3xl">
                  PHP {`${formatNumberWithComma(totalTransactionCost)}`}
                </h2>
              </div>
              {/* single card (end) */}
              {/* single card (start) */}
              <div className="inline-flex w-full md:w-full mx-6 mt-5 justify-center">
                <span className="inline-flex">
                  <input
                    id="comments"
                    name="comments"
                    type="checkbox"
                    className="h-4.5 w-4.5 mt-1 rounded border-gray-300 text-dealogikal-200 focus:ring-dealogikal-200"
                    checked={isAgree}
                    onChange={() => setIsAgree(!isAgree)}
                  />
                  <h2 className="text-md ml-2">
                    I agree to the{" "}
                    <span className="text-dealogikal-100">Closure Terms</span>{" "}
                    and <span className="text-dealogikal-100">Conditions</span>
                  </h2>
                </span>
              </div>
              {/* single card (end) */}
              {/* single card (start) */}
              <div className="inline-flex w-full md:w-full mx-10 pb-5">
                <ButtonComponent
                  text="Proceed to the Payment Review"
                  disabled={isAgree ? false : true}
                  utils={`${
                    isAgree
                      ? "bg-dealogikal-200 text-white ring-dealogikal-200"
                      : "bg-gray-400 text-white ring-gray-200"
                  } font-semibold ring-1 ring-inset text-base shadow-sm py-4 duration-100 rounded-full font-normal w-full mt-4`}
                  onClick={() => {
                    navigate(
                      `/b/booked/${reverse_auction_data.reverse_auction_id}`
                    );
                  }}
                />
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />

      {/* cja: modal calendar component */}
      <ModalComponent
        isOpen={deliveryDateModalOpen}
        onCloseHandler={() => setDeliveryDateModalOpen(false)}
        header={
          <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
            <span className="text-white inline md:text-base text-xs">
              Set Delivery Date
            </span>

            <CalendarDaysIcon
              className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
              aria-hidden="true"
            />
          </div>
        }
        body={
          <>
            <div className="flex flex-wrap justify-center mt-4">
              <ReverseAuctionBannerComponent
                margin_top="-mt-4"
                remaining_time_in_seconds={remainingTimeInSeconds}
                is_remaining_time_loading={isRemainingTimeLoading}
                setDeliveryDateModalOpen={setDeliveryDateModalOpen}
              />
              {/* single card (start) */}
              <div className="w-full md:w-full text-yellow-600 inline-flex justify-center mt-4">
                <span>
                  <ExclamationTriangleIcon className="h-5 w-5" />
                </span>
                <span className="ml-1">
                  Lead time delivery is 2 days after current date
                </span>
              </div>
              {/* single card (end) */}
              {/* single card (start) */}
              <div className="flex flex-col w-full md:w-3/5 px-6">
                <div className="mt-2">
                  <ComboboxComponent
                    hasError={deliveryDaysIsError}
                    items={daysOfDelivery}
                    placeholder="Choose days delivery"
                    selectedItem={chosenDaysDelivery}
                    setSelectedItem={setChosenDaysDelivery}
                  />
                </div>
                {deliveryDaysIsError && (
                  <div className="flex justify-center mt-2">
                    <p className="text-red-600 text-xs md:text-sm">
                      Please set up delivery schedule
                    </p>
                  </div>
                )}
                {noSelectedDateError && (
                  <div className="flex justify-center mt-2">
                    <p className="text-red-600 text-xs md:text-sm">
                      Please select a delivery date first
                    </p>
                  </div>
                )}
              </div>
              {/* single card (end) */}
              {/* single card (start) */}
              <div className="flex flex-col w-full pt-4 pb-10 md:w-full">
                <CalendarComponent
                  dateOnClickHandler={dateOnClickHandler}
                  selectedDate={selectedDate}
                  datesEnabled={datesEnabled}
                />
              </div>
              {/* single card (end) */}
              {/* single card (start) */}
              <div className="inline-flex w-full md:w-full justify-center mb-10">
                <ButtonComponent
                  text={`Cancel`}
                  utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-1/4"
                  onClick={() => setDeliveryDateModalOpen(false)}
                />
                <ButtonComponent
                  text={`${isProceeding ? "Loading..." : "OK"}`}
                  utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-1/4"
                  onClick={reviveClickHandler}
                />
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />

      <ModalComponent
        isOpen={isPriceUpdateModalOpen}
        onCloseHandler={priceUpdateModalOnCloseHandler}
        header={
          <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
            <Square3Stack3DIcon
              className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
              aria-hidden="true"
            />
            <span className="text-white inline md:text-base text-xs">
              Update Commodity Price
            </span>
          </div>
        }
        body={
          <>
            <div className="flex flex-col w-full py-2 px-5 md:w-full">
              <span className="text-lg mt-2 text-gray-500 text-center">
                You're about to update your offer price. Kindly provide the
                updated price.
              </span>
            </div>
            <div className="flex flex-wrap justify-center mt-2 mx-5">
              {/* single card (start) */}
              <div className="flex flex-col px-2 py-2 w-full md:w-1/6">
                <h2 className="text-center my-auto text-gray-500">
                  Current Price
                </h2>
              </div>
              <div className="flex flex-col px-2 py-2 w-full md:w-2/6">
                <input
                  type={"text"}
                  value={enabledPriceEditor.current_price}
                  name="txt_current_price"
                  id="txt_current_price"
                  className={`block w-full rounded-xl text-center text-xl border-0 py-4 px-4 md:mt-0 mt-2 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset ring-gray-300 focus:ring-gray-300 sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200`}
                  onChange={(e) => console.log(e.target.value)}
                  disabled={true}
                />
              </div>
              <div className="flex flex-col px-2 py-2 w-full md:w-1/6">
                <h2 className="text-center my-auto text-gray-500">
                  Updated Price
                </h2>
              </div>
              <div className="flex flex-col px-2 py-2 w-full md:w-2/6">
                <input
                  type={"text"}
                  value={enabledPriceEditor.updated_price}
                  name="txtupdatedprice"
                  id="txtupdatedprice"
                  className={`block w-full rounded-xl text-center text-xl border-0 py-4 px-4 md:mt-0 mt-2 shadow-sm ring-1 ring-inset ${
                    false ? "ring-red-600" : "ring-gray-300"
                  } placeholder:text-gray-400 focus:ring-1 focus:ring-inset ${
                    false ? "focus:ring-red-600" : "focus:ring-gray-300"
                  } text-sm sm:leading-6`}
                  onChange={(e) => updatedPriceOnChangeHandler(e)}
                />
              </div>
              {/* single card (end) */}
            </div>
            <div className="inline-flex w-full justify-center pt-10 pb-12 md:w-full">
              <ButtonComponent
                text={isUpdatingPrice ? "Updating..." : "Update"}
                utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-1/4"
                onClick={updatePrice}
              />
              <ButtonComponent
                text={`Cancel`}
                utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-1/4"
                onClick={priceUpdateModalOnCloseHandler}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default ReverseAuctionComponent;
