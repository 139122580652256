import {
  ShoppingBagIcon,
  CubeIcon,
  TagIcon,
  ChartBarIcon,
  DocumentTextIcon,
  DocumentChartBarIcon,
  TruckIcon,
  ClipboardDocumentIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { HomeIcon, TruckIcon as TruckIconSolid } from "@heroicons/react/24/solid";
import {
  destoryTokenFromLocalStorage,
  removeLocalStorageData,
} from "../../../redux/functions/function";
import { SideBarMainMenuType } from "../../../model/types/SideBarMainMenuType";

const identifyMenu = (user_role: number) => {
  switch (user_role) {
    case 1:
      return buyer_side_navigation;
    case 2:
      return seller_side_navigation;
    case 6:
      return logistic_side_navigation;
    case 7:
      return admin_side_navigation;
    default:
      return [];
  }
};

// cja: side navigations based on user roles (start)

// cja: [IMPORTANT NOTE!!] :::> menu_id should be unique to get rid of active state conflict jsx/tsx rendering
const buyer_side_navigation: SideBarMainMenuType[] = [
  {
    menu_id: "buyer_dashboard",
    name: "Dashboard",
    href: "/b/dashboard",
    icon: HomeIcon,
    current: true,
  },
  {
    menu_id: "buyer_quotation_templates",
    name: "Quotation Templates",
    href: "/b/my_auction_templates",
    icon: TagIcon,
    current: false,
  },
  {
    menu_id: "buyer_active_quotation",
    name: "Active Quotation",
    href: "/b/reverse_auction_list",
    icon: TagIcon,
    current: false,
  },
  {
    menu_id: "buyer_my_orders",
    name: "My Orders",
    href: "/b/my_orders",
    icon: ShoppingBagIcon,
    current: false,
  },
  // {
  //   menu_id: "buyer_completed",
  //   name: "Complet",
  //   href: "/b/undefined",
  //   icon: CheckCircleIcon,
  //   current: false,
  // },--ryc--reomve from buyers Menu--
  {
    menu_id: "buyer_my_vehicle",
    name: "My Vehicle",
    href: "/b/vehicle_manager",
    icon: TruckIcon,
    current: false,
  },
];

const seller_side_navigation: SideBarMainMenuType[] = [
  {
    menu_id: "supplier_dashboard",
    name: "Dashboard",
    href: "/s/dashboard",
    icon: HomeIcon,
    current: true,
  },
  {
    menu_id: "supplier_my_products",
    name: "My Products",
    href: "/my_products",
    icon: CubeIcon,
    current: false,
  },
  {
    menu_id: "supplier_my_offers",
    name: "My Offers",
    href: "/my_offers",
    icon: TagIcon,
    current: false,
  },
  // {
  //   menu_id: "supplier_my_oders",
  //   name: "My Orders",
  //   href: "/s/my_order",
  //   icon: ShoppingCartIcon,
  //   current: false,
  // },--ryc-- remove from suppliers menu--
  {
    menu_id: "supplier_my_sales",
    name: "My Sales",
    href: "/s/my_sales",
    icon: ChartBarIcon,
    current: false,
  },
  {
    menu_id: "supplier_my_accreditation",
    name: "Accredited Vehicles",
    href: "/s/my_accreditation/:status",
    icon: TruckIcon,
    current: false,
  },
];

const logistic_side_navigation: SideBarMainMenuType[] = [
  {
    menu_id: "logistic_dashboard",
    name: "Dashboard",
    href: "/l/dashboard",
    icon: HomeIcon,
    current: true,
  },
  {
    menu_id: "logistic_vehicle",
    name: "Vehicle Manager",
    href: "/l/vehicle_manager",
    icon: TruckIconSolid,
    current: false,
  },
  {
    menu_id: "logistic_my_orders",
    name: "Shipments",
    href: "/l/shipments",
    icon: DocumentTextIcon,
    current: false,
  },
  {
    menu_id: "logistic_rates",
    name: "Rates",
    href: "/l/rates",
    icon: DocumentChartBarIcon,
    current: false,
  },
  {
    menu_id: "logistic_accreditation",
    name: "Accredited Trucks",
    href: "/l/accreditation/:status_id",
    icon: TruckIcon,
    current: false,
  },
  {
    menu_id: "logistic_pre_order",
    name: "Pre-Order",
    href: "/l/pre_order/all",
    icon: ClipboardDocumentIcon,
    current: false,
  },
  {
    menu_id:"drivers",
    name:"Drivers",
    href:"/l/drivers",
    icon:UsersIcon,
    current: false,
  }
];

const admin_side_navigation: SideBarMainMenuType[] = [  
  {
    menu_id: "admin_dashboard",
    name: "Dashboard",
    href: "/a/dashboard",
    icon: HomeIcon,
    current: true,
  },
  {
    menu_id: "admin_quotation_templates",
    name: "Offers",
    href: "/a/on-going",
    icon: TagIcon,
    current: false,
  },
  {
    menu_id: "admin_quotation2_templates",
    name: "Quotation",
    href: "/a/quotation",
    icon: TagIcon,
    current: false,
  },
  {
    menu_id: "admin_transactions",
    name: "Payment",
    href: "/a/payment",
    icon: ClipboardDocumentIcon,
    current: false,
  },
  {
    menu_id: "admin_sales_order",
    name: "Orders",
    href: "/a/sales-order",
    icon: ClipboardDocumentIcon,
    current: false,
  },
  {
    menu_id: "admin_shipments",
    name: "Shipments",
    href: "/a/shipments",
    icon: ClipboardDocumentIcon,
    current: false,
  },
];

// cja: side navigations based on user roles (end)

const prevTransactions = [
  { id: 1, name: "Heroicons", href: "/", initial: "H", current: false },
  { id: 2, name: "Tailwind Labs", href: "/", initial: "T", current: false },
  { id: 3, name: "Workcation", href: "/", initial: "W", current: false },
];
const userNavigation = [
  {
    name: "Your profile",
    href: "/profile",
    callback: () => {
      /* just do nothing here... */
    },
  },
  {
    name: "Sign out",
    href: "/login",
    callback: () => {
      destoryTokenFromLocalStorage();
      removeLocalStorageData();
    },
  },
];

export {
  buyer_side_navigation,
  seller_side_navigation,
  admin_side_navigation,
  prevTransactions,
  userNavigation,
  identifyMenu,
};
